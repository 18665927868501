.order-list-page {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 20px;
	flex: 1;
	overflow: hidden;
	padding-bottom: 0;
}

.order_list_content_card {
	margin-top: 10px;
	background-color: $white;
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.oreder_list_tab {
}

.order_list_filter {
	display: flex;
	padding: 0;
	flex-wrap: wrap;
}

.order_list_table {
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
}

.order_list_pagination {
	padding: $spacer;
	border-top: 1px solid $border-color;
	display: flex;
	align-items: center;
}

.order_list_table_container {
	overflow-y: scroll;
	overflow-x: auto;
	position: relative;
}

.order_list_th_container {
	overflow-x: hidden;
	overflow-y: scroll;
	flex-shrink: 0;
	border-bottom: 1px solid $border-color;

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f0f0f0;
	}

	.order_cell {
		border: none;
		background: #eef0f4;
	}
}

.order_cell {
	border-bottom: 1px solid $border-color;
	padding: 8px 0;
	overflow: hidden;
	display: flex;
	align-items: center;
	transition: background 0.3s;
}

.order_verify_col {
	width: 90px;
	flex-shrink: 0;
}

.order_cb_col {
	width: 35px;
	flex-shrink: 0;
	position: sticky;
	left: 0;
	z-index: 9;
	padding-left: 10px;
	padding-right: $spacer;
	color: $gray-600;
}

.order_list_table_container .order_cb_col,
.order_list_table_container .order_id_col {
	background-color: $white;
}

.order_id_col {
	flex-shrink: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100px;
	padding-left: 10px;
	z-index: 9;
	padding-right: $spacer;
	position: sticky;
	left: 35px;
	&:after {
		position: absolute;
		top: 0;
		right: 5px;
		bottom: -1px;
		width: 5px;
		transform: translate(100%);
		transition: box-shadow 0.3s;
		content: '';
		pointer-events: none;
	}
}

.order_id_col.blur:after {
	box-shadow: inset 5px 0 4px -4px rgba(0, 0, 0, 0.1);
}

.order_list_table_container .order_id_col {
	cursor: pointer;
}

.order_created_col {
	min-width: 130px;
	flex: 1 0 0%;
}

.order_tag_col {
	min-width: 80px;
	flex: 1 0 0%;
}

.order_branch_col {
	flex: 1 0 0%;
	min-width: 120px;
	color: $gray-600;
}

.order_customer_col {
	flex: 1 0 0%;
	min-width: 100px;
}

.order_status_col {
	min-width: 130px;
	flex: 1 0 0%;
}

.order_payment_col {
	min-width: 140px;
	flex: 1 0 0%;
	flex-shrink: 0;
	padding-right: 10px;
}

.order_total_col {
	flex: 1 0 0%;
	min-width: 110px;
	justify-content: flex-end;
}

.order_agent_col {
	min-width: 120px;
	flex: 1 0 0%;
	flex-shrink: 0;
}

.order_phone_col {
	width: 110px;
	flex-shrink: 0;
}

.order_item_product_container {
	display: inline-flex; // so the div wont fill whitespace, when hovering into whitespace, we dont want to show product_prreview_card
	resize: none;
	padding: 0;
	padding-right: 10px;
	white-space: break-spaces;
	position: relative;
	font-weight: 500;

	.product_preview_card {
		opacity: 0;
		visibility: hidden;
		transition: visibility 100ms, opacity 100ms ease-out;
		transition-delay: 150ms;
	}
}

.order_item_product_img {
	width: 22px;
	height: 22px;
	border-radius: 4px;
}

.order_item_product_container:hover {
	.product_preview_card {
		visibility: visible;
		transition: visibility 200ms, opacity 200ms ease-out;
		transition-delay: 400ms;
		opacity: 1;
	}
}

.order_item_col {
	min-width: 200px;
	flex: 1 0 0%;
	padding-right: $spacer;
	padding-left: $spacer;
	flex-shrink: 0;
}

.order_due_date_col {
	width: 110px;
	flex-shrink: 0;
}

.order_shipping_address_col {
	width: 200px;
	flex-shrink: 0;
	padding-left: 10px;
	padding-right: 10px;
}

.order_pipeline_col {
	width: 130px;
	flex-shrink: 0;
}

.order_pipeline_stage_col {
	width: 110px;
	flex-shrink: 0;
}

.order_list_row {
	padding-top: 0;
	padding-bottom: 0;
	align-items: unset;
	cursor: pointer;
	border: none;

	&.item-row__header {
		cursor: initial;
	}
}

.order_list_row.order_list_row__unread {
	font-weight: 600;
}

.order_list_row.order_list_row__cancelled {
	opacity: 0.75;
	filter: grayscale(1);
}

.order_list_row.just_created .order_cell {
	background-color: $yellow-100 !important;
}

.order_list_table_container div.order_list_row.order_list_row__viewing .order_cell {
	background: #ecefff;
	//filter: grayscale(0);
	// border-top: 1px solid #c1cbff;
	// border-bottom: 1px solid #c1cbff;
}

.order_list_table_container .order_list_row:hover .order_cell {
	background-color: $gray-100;
}

.order_list_row.order_list_row__checked {
	background: #eff8ff;
	&:hover {
		background: #e0e9ef;
	}
}

.order_list_row.order_row_draft .order_cell {
	background-color: $gray-100;
	opacity: 0.8;
}

.order_list_expand_filter {
	width: 300px;
	flex-shrink: 0;
	border-top: 1px solid $border-color;
	border-left: 1px solid $border-color;
}

.order_list_expand_filter_section {
	padding: 8px $spacer * 1.5;
	border-bottom: 1px solid $border-color;
}

.order-form-control {
	border: none;
	background: #f0f0f0;
	display: block;
	width: 100%;
	// min-height: 32px;
	padding: 3px 10px;
	font-weight: 400;
	color: #000;
	background-clip: padding-box;
	border: none;
	border-radius: 4px;
	outline: none;
	transition: all 150ms ease-out;
	&:active {
		outline: none;
		background: #eaeaea;
	}
	&:hover {
		background: #eaeaea;
	}
}

.order_select_wrapper {
	position: relative;
}

.order_select {
	width: 100%;
	display: flex;
	align-items: center;
	@include border-radius($form-select-border-radius, 0);
	appearance: none;
	cursor: pointer;
	overflow: hidden;

	&:hover {
		outline: 0;
	}

	&.danger {
		border-color: $danger;

		&:hover {
			border-color: $danger;
		}
	}
}

.order_select.order_select__disabled {
	cursor: default;
	background: #f2f2f2;
	&:hover {
		border: $form-select-border-width solid $form-select-border-color;
	}
}

.order_select_input {
	border: none;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
	bottom: 0px;
	border: none;
	outline: none;
	z-index: -1;
	background-color: transparent;

	&.focus {
		z-index: 1;
	}
}

.field_select_wrapper {
	position: relative;
	width: 200px;

	.dropdown__item {
		font-size: $form-select-font-size;
	}
}

.order_select_placeholder {
	color: $gray-500;
}

.order_status {
	text-transform: uppercase;
	background: black;
	color: white;
	display: inline-block;
	border-radius: 4px;
	padding: 6px 6px 4px 6px; // better for uppercase
	line-height: 1;
	font-weight: 500;
	font-size: 12px;
}

.order_status.order_status__canceled {
	background: whitesmoke;
	color: $black;
}

.order_status.order_status__cancel {
	background: whitesmoke;
	color: $black;
}

.order_status.order_status__confirmed {
	background: $primary;
	color: white;
}

.order_status.order_status__open {
	background: $primary;
	color: white;
}

.order_status.order_status__processing {
	background: #17a2b8;
	color: white;
}

.order_status.order_status__shipping {
	background: $black;
	color: white;
}

.order_status.order_status__fullfilled {
	background: $success;
	color: white;
}

.order_status.order_status__hold {
	background: $danger;
	color: white;
}

.order_status.order_status__draft {
	background: $warning;
	color: white;
}

.order_status.order_status__new {
	background: $warning;
	color: white;
}

.table.order_detail_table {
	border: none;
	table-layout: fixed;
	width: 100%;
	th {
		padding: 0px 0px 8px 0;
		font-weight: 400;
		color: #aaa;
		border-bottom: 1px solid #e0e0e0;
	}

	tr {
		&:hover {
			background: white;
		}
	}
	td {
		border-bottom: 1px solid #eee;
		padding: 7px 0px 7px 0px;
	}
}

.order_list_filter_tag {
	background-color: $gray-200;
	position: relative;
	border-radius: 4px;
	padding: 3px 8px;
	margin-right: $spacer;
	display: flex;
	align-items: center;
}

.order_list_tab_badge {
	background-color: $gray-200;
	font-size: 10px;
	color: $gray-700;
	font-weight: 500;
	padding: 4px;
	border-radius: 5px;
	margin-left: $spacer * 0.5;
	line-height: 1;
	position: relative;
	top: -1px;

	&.badge__danger {
		background-color: $red-400;
		color: $white;
	}

	&.badge__warning {
		background-color: $orange;
		color: $white;
	}
}

.order_history__entry {
	display: flex;
}

.order_history__entry__logo {
	width: 15px;
	flex-shrink: 0;
	text-align: center;
	position: relative;
	margin-right: 10px;
}

.order_history__vline {
	position: absolute;
	left: 4px;
	height: 100%;
	top: 0;
	width: 1px;
	border-left: 2px dotted lightgray;
	z-index: 1;
}

.order_history__entry_icon {
	width: 5px;
	height: 5px;
	padding: 5px;
	background: #ccc;
	margin-top: 5px;
	border-radius: 50%;
	z-index: 2;
}

.order_history_entry__body {
	flex: 1;
	overflow: hidden;
	margin-bottom: 15px;
}

.order_history__entry__time {
	margin-right: 5px;
	color: #6c757d;
	font-size: 13px;
	margin-bottom: 2px;
}

.table.order_print_table {
	border: none;
	table-layout: fixed;
	width: 100%;
	th {
		padding: 5px 10px;
		border: none;
		font-size: 12px;

		font-weight: 500;
		text-transform: uppercase;
	}

	tr {
		&:hover {
			background: white;
		}
	}
	td {
		padding: 5px 10px;
		border: none;
		border-bottom: 1px solid #dee2e6;
	}
}

.filter_order_dropdown.dropdown {
	display: flex;
	flex-direction: column;
	width: 350px;
	right: -15px;
	overflow: unset;
}

.filter_order_dropdown_content {
	padding: $spacer;
	min-width: 330px;
}

.filter_order_dropdown_footer {
	padding: $spacer;
	text-align: right;
	background-color: $gray-100;
}

.filter_order_dropdown_row {
	display: flex;
	align-items: center;
	margin-bottom: $spacer;
	&:last-child {
		margin-bottom: 0;
	}
}

.fulfillment_modal_footer {
	padding: $spacer * 1.5 $spacer * 2;
	border-top: 1px solid $border-color;
	display: flex;
	align-items: center;
}

.shipping_policy_template {
	margin-bottom: $spacer;
	display: flex;
	align-items: center;
	font-size: 16px;
	color: $gray-600;
	cursor: pointer;
	padding: $spacer * 0.8 $spacer * 1.5;
	border: 1px solid $border-color;
	border-radius: 5px;

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		color: $gray-700;
		border-color: $gray-600;
	}
}

.shipping_policy_template_icon {
	margin-left: $spacer * 0.5;
	transition: 0.25s;
	width: 18px;
}

.shipping_policy_template:hover .shipping_policy_template_icon {
	transform: translateX(5px);
}

.order_modal_label {
	font-weight: 500;
}

.order_modal__header_btn {
	cursor: pointer;
	stroke: #adb5bd;
	background: #f0f0f0;
	border-radius: 50%;
	width: 28px;
	height: 28px;
	margin-left: 10px;
	display: block;
	padding: 6px;
	&:hover {
		stroke: black;
		background: #e3e3e3;
	}
}

.fulfillment_shipping_providers_container {
	border: 1px solid $border-color;
}

.fulfillment_shipping_provider {
	border-bottom: 1px solid $border-color;
	padding: $spacer * 1.5 $spacer * 2;
	display: flex;

	&:last-child {
		border-bottom: none;
	}
}

.order_box {
	background: white;
	border-radius: 8px;
	padding: 12px 17px;
	margin-bottom: 15px;
}

.order_modal_container {
	overflow-x: hidden;
	max-width: 600px;
	width: 40vw;
	overflow-y: hidden;
	height: 100%;
	min-width: 520px;
	display: flex;
	flex-direction: column;
	background: #efefef;
}

.order_modal_container_body {
	overflow-y: scroll;
	flex: 1;
	padding: 10px 15px;
}

.order_input {
	border: none;
	outline: none;
	background: #f3f3f3;
	padding: 2px 2px 2px 8px;
	border-radius: 4px;
	&:hover,
	&:focus,
	&:active {
		background: #e8e8e8;
	}
}

.order_input.order_input__calendar {
	position: relative;
	&:hover {
		cursor: pointer;
	}
}

.order_box_title {
	margin-bottom: 5px;
	display: flex;
	color: #666;
}

.shipping_provider_table.table {
	& tr:hover td {
		background-color: $white;
	}

	tr td {
		vertical-align: middle;
	}
}

.order_pos_select {
	.dropdown_link_selected {
		border-bottom: none;
		&:hover {
			border-bottom: none;
		}
	}
}

.shipping_detail_section {
	background-color: $white;
	border-radius: $spacer * 1.5;
	padding: $spacer * 2;
	flex: 1;
	flex-shrink: 0;
	overflow: hidden;
}

.shipping_detail_label {
	font-size: 14px;
	color: $gray-600;
}

.shipping_status_icon {
	width: 15px;
	height: 15px;
	flex-shrink: 0;
}

.shipping_track_container {
	display: flex;
}

.shipping_tracker {
	display: flex;
	flex-direction: column;
	margin-right: $spacer;
	position: relative;

	&:after {
		content: '';
		top: 10px;
		height: 100px;
		left: 1px;
		width: 50%;
		position: absolute;
		background-color: transparent;
		border-right: 2px dashed $border-color;
	}
}

.shipping_tracker_arrival_icon {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid $border-color;
	position: relative;
	background-color: $white;
	top: 4px;
	z-index: 1;
}

.shipping_tracker_destiantion_icon {
	width: 12px;
	height: 12px;
	border: 2px solid $border-color;
	position: relative;
	z-index: 1;
	top: 4px;
	background-color: $white;
}

.shipping_track_content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.order_filter_input {
	white-space: nowrap;
	background-color: #eee;
	padding: $spacer * 0.5 $spacer;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;

	&.active {
		background-color: rgba($blue-100, 0.35);
	}

	.date-range-input {
		background-color: transparent;
		border: none;
		outline: none;
		padding: 0;
		min-height: unset;

		&:focus,
		&:hover {
			border: none;
			outline: none;
		}
	}

	.force_display .date-range-input {
		border: 1px solid #cacaca;
		padding: $spacer * 0.5 $spacer;

		&:focus,
		&:hover {
			border-color: initial;
		}
	}
}

.order_filter_x {
	width: 16px;
	height: 16px;
	background-color: $gray-400;
	cursor: pointer;
	line-height: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: $white;

	&:hover {
		background-color: $gray-500;
		color: $gray-100;
	}
}

.order_filter_search_input.input_search__input {
	background-color: #eee;
	border: none;
	outline: none;

	&.active {
		background-color: rgba($blue-100, 0.35);
	}

	&:focus,
	&:hover {
		border: none;
		outline: none;
	}
}

.board-header-btn-divider {
	border-left: 1px solid #0000003d;
	float: left;
	height: 16px;
	margin: 8px;
}

.order_pipeline_select {
	background-color: #6c757d;
	color: #fff;
	box-shadow: inset 0 2px 3px rgb(0 0 0 / 30%);
	font-size: 14px;
	height: 29px;
}

.order_updates {
	position: relative;
	cursor: pointer;
	color: #888;
	&:hover {
		color: black;
	}
	display: flex;
	align-items: center;
}

.order_updates_count {
	position: absolute;
	bottom: -5px;
	right: -8px;
	font-size: 11px;
	font-weight: 600;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 50%;
	padding: 4px;
	min-width: 20px;
}

.order_noti {
	overflow: hidden;
	padding: 10px 10px;
	display: flex;
	align-items: center;
	border-radius: 6px;
	cursor: pointer;
	transition: background ease-out 500ms;
	&:hover {
		transition: background ease-out 100ms;
		background: #eee;
	}
}

.order_noti.order_noti__unread {
	transition: background ease-out 100ms;
	background: #dbefff;
}

.instant_noti__noti_content {
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	overflow: hidden;
}

.order_task_label {
	margin: 0;
	cursor: pointer;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	overflow: hidden;
}

.order_task {
	display: flex;
	margin-top: 5px;
}

.dropdown_link_selected_full {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #333;
	&:hover {
		color: black;
		border-bottom: none;
	}
}

.order_modal__discount_modal {
	text-align: left;
	width: 250px;
	z-index: 110;
	position: absolute;
	top: 0px;
	right: 0;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	border-radius: 8px;
	background: white;
	white-space: normal;
}

.order_modal__discount_modal__head {
	padding: 7px 20px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #eeeeee;
}

.order_modal__discount_modal__body {
	padding: 10px 20px;
	background: whitesmoke;
}

.user_preview_card__head {
	font-size: 14px;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 7px;
}

.user_preview_card {
	width: 260px;
	position: fixed;
	background: $white;
	padding: $spacer;
	border: 1px solid $border-color;
	box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	z-index: 99;
	font-size: 13px;
	cursor: initial;
	color: $gray-700;
	margin-left: -200px;
}

.user_preview_card_title {
	font-size: 15px;
	font-weight: 500;
	line-height: 1.25;
}

.user_preview_card_image {
	width: 100%;
	height: 150px;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: $spacer;
	border: 1px solid $border-color;
	overflow: hidden;
}

.user_order_container {
	position: relative;

	.user_preview_card {
		opacity: 0;
		visibility: hidden;
		transition: visibility 100ms, opacity 100ms ease-out;
		transition-delay: 150ms;
	}
}

.user_order_container:hover {
	.user_preview_card {
		visibility: visible;
		transition: visibility 200ms, opacity 200ms ease-out;
		transition-delay: 400ms;
		opacity: 1;
	}
}

.preview_order_modal {
	display: flex;
	overflow: hidden;
	width: 1000px;
	border-radius: 8px;
}

.preview_order_modal_sidebar {
	background: #424242;
	padding: 20px 30px;
	width: 250px;
	flex-shrink: 0;
}

.preview_order_modal_sidebar_item {
	padding: 5px 0;
	color: #a6a6a6;
	cursor: pointer;
	user-select: none;

	&:hover {
		color: #e6e6e6;
	}

	&.active {
		color: #fff;
		font-weight: 500;
	}
}

.preview_order_modal_content {
	height: 600px;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.preview_order_modal_content_header {
	margin-bottom: 30px;
	margin-top: 40px;
	text-align: center;
	flex-shrink: 0;
}

.preview_order_modal_content_title {
	font-size: 24px;
	font-weight: 600;
}

.order_preview_body {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 1;
	transition: all 250ms ease-out;
	transition-delay: 100ms;
}

.order_preview_body.order_preview_body__hidden {
	left: 500px;
	opacity: 0;
	transition: all 150ms ease-out;
}

.order_item_img {
	width: 24px;
	height: 24px;
	object-fit: cover;
	border-radius: 4px;
}

.order_modal_item_img {
	width: 22px;
	height: 22px;
	object-fit: cover;
	border-radius: 4px;
}

.order_tag {
	display: flex;
	align-items: center;
}

.order_tag_dot {
	width: 12px;
	height: 12px;
	border-radius: 4px;
}

.order_tag_text {
	margin-left: 5px;
	white-space: nowrap;
	overflow: hidden;
	max-width: 120px;
	text-overflow: ellipsis;
}

.order_tag_x {
	margin-left: 5px;
	cursor: pointer;
	visibility: hidden;
	display: flex;
	align-items: center;
}

.order_tag_x.loading {
	visibility: visible;
}

.order_tag:hover .order_tag_x {
	visibility: visible;
}

.order_internal_notes {
	padding: 5px 10px;
	margin: 0;
	font-size: 14px;
	border: none;
	background: #f3f3f3;
	border-radius: 6px;
	width: 100%;

	&:hover {
		outline: 0;
	}

	&:active,
	&:focus {
		outline: 0;
	}
}

.order_label {
	position: absolute;
	top: 45px;
	right: 10px;
	z-index: 100;
	min-width: 90px;
	height: 28px;
	border: 2px dashed #ff980e;
	padding-left: 10px;
	padding-right: 10px;
	transform: rotate(23.3deg);
}

.order_label.draft {
	background-color: rgba(255, 249, 240, 0.8);
	border-color: #ff980e;
	color: #ff980e;
}

.order_label.unconfirmed {
	background-color: rgba(255, 249, 240, 0.8);
	border-color: #f3aa18;
	color: #f3aa18;
}

.order_label.cancelled {
	background-color: rgba(0, 0, 0, 0.05);
	color: #333;
	border-color: #8b8b8b;
}

.order_label_content {
	margin: 0;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	text-align: center;
	cursor: default;
}

.order_modal_general_info_label {
	width: 70px;
	white-space: nowrap;
	font-size: 14px;
	overflow: hidden;
	margin-right: 8px;
	text-overflow: ellipsis;
	color: $gray-600;
	flex-shrink: 0;
}

.btn.order_add_payment_btn {
	background-color: #ff980e;
	color: $white;
	width: 100%;
	font-weight: 500;
	border-radius: 6px;
}

.order_modal_item_row {
	width: 100%;
	display: flex;
	border-bottom: 1px solid #eee;
	padding: 7px 0;
}

.shipping_track_content_address {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.order_pipeline_card_description {
	white-space: pre-wrap;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-size: 13px;
	@extend .text__muted;
}
