.dropdown {
	z-index: $zindex-dropdown;
	max-width: 350px;
	max-height: 350px;
	overflow-x: hidden;
	position: absolute;
	min-width: 200px;
	top: 100%;
	right: 0;

	background: $white;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
}

.dropdown.dropdown__right {
	right: unset;
	left: 0;
}

.dropdown.dropdown__up {
	top: unset;
	bottom: 100%;

	&.dropdown__full {
		top: unset;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.dropdown__item.dropdown__item_group {
	padding: $spacer * 0.5 0;
	margin: 0 $spacer;
	display: flex;
	font-size: 12px;
	align-items: center;
	cursor: default;
	font-weight: 500;
	text-transform: uppercase;
	color: $gray-600;
	border-top: 1px solid $gray-300;
	background: $white;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:first-child {
		border-top: none;
	}
	&:hover {
		color: $gray-600;
		background: $white; // #E8F0FF;
	}
}

.dropdown__item {
	padding: $spacer $spacer;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	cursor: pointer;
	background: $white;
	color: $black;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	// always keep normal text
	font-size: initial;
	font-weight: initial;

	&:first-child {
		padding-top: 1.16 * $spacer;
	}

	&:last-child {
		padding-bottom: 1.16 * $spacer;
	}

	&:hover {
		color: white;
		background: $primary; // #E8F0FF;
		.convo_header__dropdown_icon {
			color: white;
		}
		// background: #E8F0FF;
	}

	&.dropdown__item__hover-light:hover {
		background: $gray-200;
		color: initial;

		&.dropdown__item__hover-light.no_hover:hover {
			background-color: $white;
			color: initial;
		}
	}

	&.dropdown__item__hover-light.active {
		background: $gray-200;
		color: initial;
	}
}

.dropdown__item__text {
	overflow: hidden;
	font-size: 13px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.dropdown__item.dropdown__item__selected {
	color: white;
	background: $primary; // #E8F0FF;
}

.dropdown__item.dropdown__item__thin {
	padding: $spacer * 0.5 $spacer;
	font-size: 14px;
}

.dropdown__item.dropdown__item__disabled {
	color: $gray-400;
	cursor: default;

	.convo_header__dropdown_icon {
		stroke: $gray-200;
	}

	&:hover {
		color: $gray-400;
		stroke: $gray-400;
		background: $white; // #E8F0FF;
		.convo_header__dropdown_icon {
			stroke: $gray-200;
		}
		// background: #E8F0FF;
	}
}

input.form-control.dropdown__input {
	background: $white;
	cursor: pointer;
	padding-right: $spacer * 4;
}

.dropdown__input_container {
	max-width: 300px;
	position: relative;
}

.dropdown_link__container {
	display: inline-block;
	position: relative;
	max-width: 100%;
}

.dropdown_link__selected {
	cursor: pointer;
	padding-top: 1px;
	border-bottom: 1px dashed $gray-600;
	@include text-truncate;
	display: inline;
	&:hover {
		color: $primary;
		border-bottom: 1px dashed $primary;
	}
}

.dropdown.dropdown__full {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	top: 110%;
	width: 100%;
	max-width: 100%;
}

.dropdown__input__more {
	cursor: pointer;
	position: absolute;
	top: 8px;
	right: $spacer;
	fill: $gray-800;
	stroke: $gray-800;
}

.dropdown__input-search-container {
	padding: $spacer;
	background-color: $gray-100;
	flex: 1;
}

.dropdown__content-container {
	max-height: 300px;
	overflow: auto;
}

.dropdown__item__with_desc {
	padding: math.div($spacer, 2) $spacer;
	flex-shrink: 0;
	align-items: center;
	cursor: pointer;
	background: $white;
	color: $black;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	// always keep normal text
	font-size: initial;
	font-weight: initial;

	&:first-child {
		padding-top: 0.58 * $spacer;
	}

	&:last-child {
		padding-bottom: 0.58 * $spacer;
	}

	&:hover {
		color: white;
		background: $primary; // #E8F0FF;
		.convo_header__dropdown_icon {
			color: white;
		}

		.dropdown__item__desc {
			color: white;
		}
		// background: #E8F0FF;
	}

	&.dropdown__item__hover-light:hover {
		background: $gray-200;
		color: initial;
	}

	&.dropdown__item__hover-light.active {
		background: $gray-200;
		color: initial;
	}
}

.dropdown__item__desc {
	overflow: hidden;
	color: #999;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 1;
}

.dropdown__item__with_desc.dropdown__item__selected {
	color: white;
	background: $primary; // #E8F0FF;

	.convo_header__dropdown_icon {
		color: white;
	}

	.dropdown__item__desc {
		color: white;
	}
}

// new dropdown

.dropdown_input {
	width: 100%;
	display: flex;
	align-items: center;
	height: 34px;
	max-height: 100%;
	padding: $form-select-padding-y ($form-select-padding-x + $form-select-indicator-padding) $form-select-padding-y
		$form-select-padding-x;
	padding-right: 50px;
	font-family: $form-select-font-family;
	font-size: $form-select-font-size;
	font-weight: $form-select-font-weight;
	line-height: $form-select-line-height;
	color: $body-color;
	background-color: $form-select-bg;
	background-image: escape-svg($form-select-indicator);
	background-repeat: no-repeat;
	background-position: $form-select-bg-position;
	background-size: $form-select-bg-size;
	border: $form-select-border-width solid $form-select-border-color;
	@include border-radius($form-select-border-radius, 0);
	@include box-shadow($form-select-box-shadow);
	appearance: none;
	cursor: pointer;
	overflow: hidden;

	&:hover {
		border-color: $form-select-focus-border-color;
		outline: 0;
		color: $black;
	}

	&.danger {
		border-color: $danger;

		&:hover {
			border-color: $danger;
		}
	}

	&.dropdown_input__small {
		min-height: unset;
		padding: 2px 6px;
		line-height: 20px;
		border-radius: 4px;
		height: auto;
		padding-right: 25px;
		background-size: 10px 8px;
		background-position: right 10px center;
	}
}

.dropdown_input_placeholder {
	color: $gray-500;
}

.dropdown_item_img {
	width: 24px;
	height: 24px;
	object-fit: cover;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdown_item {
	padding: $spacer * 0.4 $spacer;
	cursor: pointer;
	background-color: $white;
	position: relative;

	&.active {
		background-color: $gray-200;
	}

	&.checked {
		background-color: rgba($primary, 1);
		color: $white;

		.dropdown_item_text {
			color: $white;
		}

		.dropdown_item_subtext {
			color: $white;
		}
	}

	&.dropdown_item__bold.checked {
		background-color: rgba($primary, 0.2);
		.dropdown_item_text {
			color: $gray-700;
		}

		.dropdown_item_subtext {
			color: $gray-600;
		}
	}

	&.dropdown_item__bold.active {
		background-color: rgba($primary, 1);
		color: $white;

		.dropdown_item_text {
			color: $white;
		}

		.dropdown_item_subtext {
			color: $white;
		}
	}

	&.dropdown_item__disabled {
		color: $gray-500;
		pointer-events: none;
	}
}

.dropdown_item.dropdown_item_wide {
	padding: 10px;
	font-size: 16px;
}

.dropdown_item_subtext {
	color: $gray-600;
}

.dropdown_item_cotainer {
	max-height: 230px; // to display 6 defualt record
	// min-height: 200px; // to display 6 defualt record
	overflow: auto;
}

.dropdown_filter_container {
	padding: math.div($spacer, 2) $spacer;
	background-color: whitesmoke;
}

.dropdown_filter_input_container {
	position: relative;
}

.form-control.dropdown_filter_input {
	padding: 3px $spacer * 2.5;
	font-size: 13px;
	min-height: 27px;
	&:active,
	&:focus,
	&:hover {
		outline: none;
	}
}

.dropdown_filter_input_search_icon {
	color: $gray-600;
	position: absolute;
	top: 50%;
	left: $spacer * 0.8;
	transform: translateY(-50%);
}

.dropdown_filter_input_x_icon {
	position: absolute;
	top: 50%;
	right: $spacer * 0.8;
	transform: translateY(-50%);
	cursor: pointer;
	padding: 2px;
	background: $gray-300;
	stroke: $white;
	border-radius: 50%;
	stroke-width: 3px;
	transition: all 150ms ease-out;

	&:hover {
		background: $gray-500;
	}
}

.dropdown_link_selected {
	display: inline;
	cursor: pointer;
	padding-top: 1px;
	border-bottom: 1px dashed #aaa;

	&:hover {
		color: $primary;
		border-bottom: 1px dashed $primary;
	}
}

.dropdown_input_border_left_none {
	border-left: none;
}

.dropdown_input_border_left_radius_none {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.dropdown_input_border_right_none {
	border-right: none;
}

.dropdown_input_border_right_radius_none {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.dropdown_input.disabled {
	cursor: default;
	background: $input-disabled-bg;
	pointer-events: none;
	&:hover {
		border: $form-select-border-width solid $form-select-border-color;
	}
}

.hover_dropdown {
	z-index: 1200;
	background-color: $white;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	opacity: 0;
	visibility: hidden;
	position: relative;
	transition: opacity 0.3s;

	&.is-primary {
		background-color: $primary;
		color: $white;

		&.top-left:after,
		&.top-right:after,
		&.top-center:after {
			border-top-color: $primary;
		}

		&.bottom-left:after,
		&.bottom-right:after,
		&.bottom-center:after {
			border-bottom-color: $primary;
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}

	&.bottom-center {
		&:after {
			content: '';
			position: absolute;
			bottom: 100%;
			left: calc(50% - 8px);
			border: solid transparent;
			border-bottom-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 16px;
			background-color: transparent;
		}
	}

	&.bottom-left {
		&:after {
			content: '';
			position: absolute;
			bottom: 100%;
			left: var(--edge);
			border: solid transparent;
			border-bottom-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 16px;
			background-color: transparent;
		}
	}

	&.bottom-right {
		&:after {
			content: '';
			position: absolute;
			bottom: 100%;
			right: var(--edge);
			border: solid transparent;
			border-bottom-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 16px;
			background-color: transparent;
		}
	}

	&.top-center {
		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: calc(50% - 8px);
			border: solid transparent;
			border-top-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 16px;
			background-color: transparent;
		}
	}

	&.top-left {
		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: var(--edge);
			border: solid transparent;
			border-top-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 16px;
			background-color: transparent;
		}
	}

	&.top-right {
		&:after {
			content: '';
			position: absolute;
			top: 100%;
			right: var(--edge);
			border: solid transparent;
			border-top-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 16px;
			background-color: transparent;
		}
	}

	&.left-bottom {
		&:after {
			content: '';
			position: absolute;
			left: 100%;
			top: var(--edge);
			border: solid transparent;
			border-left-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 100%;
			width: 16px;
			height: 100%;
			background-color: transparent;
		}
	}

	&.left-top {
		&:after {
			content: '';
			position: absolute;
			left: 100%;
			bottom: var(--edge);
			border: solid transparent;
			border-left-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 100%;
			width: 16px;
			height: 100%;
			background-color: transparent;
		}
	}

	&.left-center {
		&:after {
			content: '';
			position: absolute;
			left: 100%;
			bottom: calc(50% - 8px);
			border: solid transparent;
			border-left-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 100%;
			width: 16px;
			height: 100%;
			background-color: transparent;
		}
	}

	&.right-bottom {
		&:after {
			content: '';
			position: absolute;
			right: 100%;
			top: var(--edge);
			border: solid transparent;
			border-right-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 100%;
			width: 16px;
			height: 100%;
			background-color: transparent;
		}
	}

	&.right-top {
		&:after {
			content: '';
			position: absolute;
			right: 100%;
			bottom: var(--edge);
			border: solid transparent;
			border-right-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 100%;
			width: 16px;
			height: 100%;
			background-color: transparent;
		}
	}

	&.right-center {
		&:after {
			content: '';
			position: absolute;
			right: 100%;
			bottom: calc(50% - 8px);
			border: solid transparent;
			border-right-color: white;
			border-width: 8px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 100%;
			width: 16px;
			height: 100%;
			background-color: transparent;
		}
	}
}

.emoji_picker_dropdown_emojis {
	padding: 0 $spacer;
	display: grid;
	grid-gap: $spacer;
	grid-template-columns: repeat(8, 1fr);
}

.emoji_picker_dropdown_emoji_item {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	height: 24px;

	&:hover {
		background-color: $gray-200;
	}
}

.dropdown-trigger {
	display: inline-block;
}

.dropdown_input.dropdown_input__no_border {
	border: none;
	outline: none;
}

.dropdown_input.dropdown_input__light {
	background-color: #f3f3f3;

	&.dropdown_input__success {
		color: $success;
		background-color: #d4edda;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2319b600' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	}

	&.dropdown_input__primary {
		color: $primary;
		background-color: #dceaff;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%230d6efd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	}
}

.dropdown_input.dropdown_input__success {
	color: $white;
	background-color: $success;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.dropdown_item__seperator {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  background-color: $border-color;
}
