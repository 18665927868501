$agent-message-background-color: white; // #dce1ef !default;
$agent-message-text-color: black; // #14093a !default;

.messages {
	flex: 4;

	border-right: 1px solid #e9ecef;
	position: relative;

	display: flex;
	position: relative;
	overflow: hidden;
	vertical-align: top;
	background: #f0f2f5;
	flex-direction: column;
	border-left: 1px solid $section-border-color;
	border-right: 1px solid $section-border-color;

	&.email {
		background-color: whitesmoke;
	}
	&.instagram_comment {
		background: #f0943333;
		background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c22 25%, #dc274322 50%, #cc236622 75%, #bc188822 100%);
		background: -webkit-linear-gradient(
			45deg,
			#f0943322 0%,
			#e6683c22 25%,
			#dc274322 50%,
			#cc236622 75%,
			#bc188822 100%
		);
		background: linear-gradient(45deg, #f0943322 0%, #e6683c22 25%, #dc274322 50%, #cc236622 75%, #bc188822 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
	}
}

.convo_events {
	margin: 0 auto;
	overflow-x: auto;
	overflow-y: scroll;
	width: 100%;
	padding-left: 8px; // equal 1 scroll
	padding-bottom: 40px;

	width: 100%;
	flex: 1 1 0;
	position: relative;
	display: flex;
	flex-direction: column-reverse;

	&.email {
		max-width: unset;
	}
}

.convo_events.convo_events__comment {
	padding-top: 20px;
}

.convo_events__empty_img {
	margin: 0 auto;
	display: block;
	margin-top: 350px;
	width: 200px;
}

/* Style message bottom*/

.convo_event__time {
	display: block;
	text-align: center;
	margin-top: $spacer * 2;
	color: $gray-600;
}

.message_file {
	border-radius: 7px;
	padding: $spacer;
	margin-top: $spacer * 0.5;
	border-radius: 10px;
	cursor: pointer;
	max-width: calc(100% - 50px);
	overflow: hidden;
}

.message_file.message_file__comment {
	margin-right: 0;
	color: $black;
	background-color: $white;
	border: 1px solid #aaa;
	max-width: 100%;
}

.message_file.message_file__agent {
	margin-right: $spacer * 5;
	color: $agent-message-text-color;
	background-color: $agent-message-background-color;
	align-self: flex-end;
}

.message_file.message_file__user {
	margin-left: $spacer * 5;
	color: $black;
	background-color: $white;
	box-shadow:
		0px 1px 4px rgba(0, 0, 0, 0.05),
		0px 4px 36px rgba(0, 0, 0, 0.05);
}

.message_file__link {
	display: flex;
	flex-direction: row;
	align-items: center;
	&:hover {
		.message_file__download {
			stroke: $primary;
		}
	}
}

.message_file__download {
	margin-right: $spacer;
	flex-shrink: 0;
}

.message_order {
	margin-top: 10px;
	color: $black;
	border: 1px solid #aaa;
	background: white;
	border-radius: 8px;
	width: 250px;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 10px;
}

.message_order.message_order__agent {
	margin-right: $spacer * 5;
	align-self: flex-end;
}

.message_order.message_order__user {
	margin-left: $spacer * 5;
}

.message_file__filename {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.message_file__size {
	color: $gray-800;
}

// LINK

.message_link.message_link__user {
	display: inline-block;
	margin-left: $spacer * 5;
}

.message_link.message_link__agent,
.message_link.message_link__dummy,
.message_link.message_link__workflow {
	display: inline-block;
	margin-right: $spacer * 5;
}

.message_link.message_image__link,
.message_image.message_link__dummy {
	display: inline-block;

	margin-right: $spacer * 5;
	align-self: flex-end;
}

.message_seen {
	display: inline-block;
	margin-right: $spacer * 5;
	align-self: flex-end;
	color: $text-muted;
	margin-top: $spacer * 0.5;
}

.message_link__img {
	margin-top: 5px;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
	max-height: 450px;
	max-width: 100%;
	object-fit: contain;
	display: block;
	border-left: 3px solid $gray-400;
	padding-left: $spacer;
}

.message_link__description {
	background: $white;
	border: 1px solid #eeeeee;
	box-shadow:
		0px 1px 4px rgba(0, 0, 0, 0.05),
		0px 4px 36px rgba(0, 0, 0, 0.05);
	padding: $spacer;
	white-space: pre-wrap;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	word-break: break-word;
}

.message_link {
	margin-top: $spacer * 0.5;
	position: relative;
}

// IMAGE
.message_image.message_image__user {
	display: inline-block;
	margin-right: $spacer;
}

.message_image.message_image__agent,
.message_image.message_image__dummy {
	display: inline-block;
	justify-content: flex-end;
}

.message_image__img {
	max-height: 350px;
	height: 100%;
	max-width: 100%;
	object-fit: contain;
	border-radius: 8px;
	min-width: 70px;
	overflow: hidden;
	border: 1px solid #e0e0e0;
	&:hover {
		border: 1px solid #bbb;
	}
}

.message_image {
	margin-top: $spacer * 0.5;
	position: relative;
}

.message_video.message_video__user {
	display: inline-block;
	margin-left: $spacer * 5;
}

.message_video.message_video__agent,
.message_video.message_video__dummy {
	display: inline-block;
	margin-right: $spacer * 5;
	align-self: flex-end;
}

.message_video {
	margin-top: $spacer * 0.5;
	position: relative;
}

.message_video__video {
	border-radius: 10px;
	width: 100%;
	height: 100%;
	&:focus {
		outline: none;
	}
}

.message_markdown.message_markdown__user {
	display: inline-block;
	margin-left: $spacer * 5;

	color: $black;
	background: $white;
	border: 1px solid #eeeeee;
	box-shadow:
		0px 1px 4px rgba(0, 0, 0, 0.05),
		0px 4px 36px rgba(0, 0, 0, 0.05);
}

.message_markdown.message_markdown__agent,
.message_markdown.message_markdown__workflow,
.message_markdown.message_markdown__dummy {
	display: inline-block;
	margin-right: $spacer * 5;
	color: $agent-message-text-color;
	background: $agent-message-background-color;
	align-self: flex-end;
}

.message_markdown {
	border-radius: 7px;
	word-break: break-word;
	position: relative;
	padding: $spacer;
	margin-top: $spacer * 0.5;
	max-width: 100%;
	overflow: auto;
}

.message_container_wrapper {
	max-width: 750px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	position: relative;

	&.error {
		.message_text,
		.message_file {
			background-color: #feecf0;
		}
	}
}

.message_text.message_text__deleted {
	text-decoration: line-through;
}

.message_text.message_text__user {
	display: inline-block;
	margin-left: $spacer * 5;

	color: #14093a; // $black;
	background-color: #dce1ef; // $white;

	// color: $black;
	// background: white;
}
.message_text {
	white-space: pre-wrap;
	border-radius: 7px;
	word-break: break-word;
	position: relative;
	padding: 10px 15px;
	margin-top: $spacer * 0.5;
}

.message_text.message_text__agent,
.message_text.message_text__workflow,
.message_text.message_text__dummy {
	display: inline-block;
	margin-right: $spacer * 5;
	color: $agent-message-text-color;

	// background: #333;
	background: $agent-message-background-color;
	align-self: flex-end;
}
.message_text.message_text__workflow {
	background-color: #fff5ce;
}

.message_html.message_html__user {
	margin-left: $spacer * 5;
}

.message_html__email_to {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.message_html__email_subject {
	font-weight: 500;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.message_html__email_label {
	font-weight: normal;
	color: $gray-600;
	width: 60px;
	display: inline-block;
	text-align: right;
	margin-right: $spacer * 0.5;
}

.message_html__header {
	padding-bottom: $spacer * 2;
	margin-bottom: $spacer;
	border-bottom: 2px dashed $gray-300;
}

.message_html {
	position: relative;
	padding: $spacer * 2;
	margin-top: $spacer;
	color: $black;
	background: $white;
	width: 100%;
	overflow: auto;
	border: 1px solid #bababa;
	box-shadow:
		0px 1px 4px rgba(0, 0, 0, 0.05),
		0px 4px 36px rgba(0, 0, 0, 0.05);
}

.message_html.message_html__agent,
.message_html.message_html__dummy {
	margin-right: $spacer * 5;
	align-self: flex-end;
}

.message-text--error {
	color: rgb(72, 66, 66);
}

.message_audio.message_audio__user {
	display: inline-block;
	margin-left: $spacer * 5;
}

.message_audio.message_audio__agent,
.message_audio.message_audio__dummy {
	display: inline-block;
	margin-right: $spacer * 5;
	align-self: flex-end;
}

.message_audio {
	margin-top: $spacer * 0.5;
	position: relative;

	width: 100%;
	margin: 5px 0 0 0;
	height: 45px;
	display: inline;
}

.message_postback.message_postback__user {
	display: inline-block;
	margin-left: $spacer * 5;
}

.message_postback.message_postback__agent,
.message_postback.message_postback__dummy {
	display: inline-block;
	margin-right: $spacer * 5;
	align-self: flex-end;
}

.message_postback {
	margin-top: $spacer * 0.5;

	position: relative;

	background: $gray-300;

	white-space: pre-wrap;
	border-radius: 7px;
	word-break: break-word;
	position: relative;
	padding: 7px $spacer;
	margin-top: $spacer * 0.5;

	color: $black;
	background: #f0f0f0aa;
}

.message_audio__audio {
	all: inherit;
	width: 100%;
}

.message__info {
	text-align: center;
	color: $gray-600;
	margin: 10px;
}

.message_state {
	position: absolute;
	z-index: 1;
	bottom: 0px;
	display: flex;
	align-items: center;
	right: $spacer * 3;
	display: block;
}

.message_state.message_state__received {
	width: 12px;
	height: 12px;
	color: $white;

	padding: 2px;
	border-radius: 50%;
	background: $gray-500;
}

.message_state.message_state__error {
	right: $spacer * 3 - 1px;
	width: 14px;
	fill: $yellow; //$danger;
	// stroke: #900000;
	stroke: $danger;
}

.message_state.message_state__seen {
	width: 13px;
	color: $success;
}

.message_state.message_state__sending {
	width: 13px;
	color: $gray-400;
}

.message_state.message_state__sent {
	width: 12px;
	height: 12px;
	color: $white;

	padding: 2px;
	border-radius: 50%;
	background: $gray-300;
}

.message_container {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 70%;
	&:hover {
		.message_actions {
			display: flex;
		}
	}
}

.message_actions.message_actions__show {
	display: flex;
}

.message_actions {
	display: none;
	align-items: center;
	margin-left: $spacer;
}

.message_actions__action {
	cursor: pointer;
	color: $gray-600;
	stroke: $gray-600;
	margin-left: $spacer;
	position: relative;
	&:hover {
		color: $primary;
		stroke: $primary;
	}
}

.message_actions__action.message_actions__action__disabled {
	cursor: default;
	color: $gray-300;
	stroke: $gray-300;
	&:hover {
		color: $gray-300;
		stroke: $gray-300;
	}
}

.message_container.message_container__dummy {
	align-self: flex-end;
}

.generic-attachment-container.generic-attachment-container__dummy,
.generic-attachment-container.generic-attachment-container__workflow,
.generic-attachment-container.generic-attachment-container__agent {
	align-self: flex-end;
	padding-right: 50px;
	max-width: 80%;
	overflow: hidden;
}

.message_container.message_container__agent,
.message_container.message_container__workflow {
	flex-direction: row;
	justify-content: flex-end;
	align-self: flex-end;
}

.message_container.message_container__comment {
	display: flex;
	max-width: 100%;
	overflow: hidden;
	flex-direction: row;
	width: unset;

	.message_text {
		margin-top: 0;
		border-radius: 8px;
		margin-left: 0;
		color: $black;
		background: #f5f5f5;
	}
}

.message_container.message_container__user {
	display: flex;
	flex-direction: row;
}
.message_container.message_container__user.message_container__image {
	padding-left: 50px;
}

.message_container.message_container__dummy.message_container__image,
.message_container.message_container__workflow.message_container__image,
.message_container.message_container__agent.message_container__image {
	padding-right: 50px;
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}

.text_message_sender.text_message_sender__agent,
.text_message_sender.text_message_sender__dummy {
	justify-content: flex-start;
	flex-direction: row-reverse;
}

.text_message_sender {
	display: flex;
	align-items: flex-end;
	margin-top: $spacer * 2;
}

.text_message_sender__avatar {
	width: $spacer * 5;
	display: flex;
	justify-content: center;
}

.text_message_sender__name {
	display: flex;
	align-items: center;
	color: $gray-700;
	max-width: 50%;
}

.message-system {
	text-align: center;
	margin: 10px;
	color: $gray-600;
}

.message_form.message_form__agent,
.message_form.message_form__dummy {
	display: inline-block;
	margin-right: $spacer * 5;
	align-self: flex-end;
}

.message_form.message_form__user {
	display: inline-block;
	margin-left: $spacer * 5;
}

.message_form {
	margin-top: $spacer * 0.5;
	position: relative;

	display: flex;
	flex-direction: column;
	padding: 10px;
	-webkit-box-shadow: 0 0 10px $gray-300;
	box-shadow: 0 0 10px $gray-300;
	border-radius: 7px;
	margin-top: 10px;
}

.form-attachment-field {
	display: flex;
	flex-direction: column;
	margin-bottom: 0px;
}

.form-attachment-field__title {
	color: #4c4c4c;
}

.form-attachment-field__text {
	width: 100%;
	border: 1px solid #dbdbdb;
	padding: 10px;
	margin: 3px 0px 0px 0px;
	border-radius: 7px;
}

.input--error {
	border: 1px solid red;
}

.input--success {
	background-color: #f9f9f9;
	pointer-events: none;
}

.form-attachment-field__error {
	color: brown;
	float: right;
}

.form-attachment-field__checkbox {
	width: 100%;
	all: inherit;
	-webkit-appearance: textfield;
	margin-right: 8px;
}

.form-attachment-field__checkbox:hover {
	cursor: pointer;
}

.form-attachment-field__list {
}

.form-attachment-field__list-item {
	display: flex;
	align-items: center;
	margin: 4px;
	margin-left: 0px;
}

.form-attachment-field__radio {
	width: 100%;
	all: inherit;
	-webkit-appearance: textfield;
	margin-right: 8px;
}

.form-attachment-field__datetime {
	width: 100%;
	border: 1px solid #dbdbdb;
	padding: 10px;
	margin: 3px 0px 0px 0px;
	border-radius: 7px;
	// background: url(../img/calendar.svg) no-repeat scroll;
	// background-size: 20px 20px;
	// background-position: right 10px center;
}

.red {
	color: $red;
}

.message_button.message_button__user {
	display: inline-block;
	margin-left: $spacer * 5;

	border: 1px solid #eeeeee;
	box-shadow:
		0px 1px 4px rgba(0, 0, 0, 0.05),
		0px 4px 36px rgba(0, 0, 0, 0.05);
}

.message_button {
	white-space: pre-wrap;
	border-radius: 7px;
	word-break: break-word;
	position: relative;
	margin-top: $spacer * 0.5;
	min-width: 200px;
}

.message_button.message_button__agent,
.message_button.message_button__dummy {
	display: inline-block;
	margin-right: $spacer * 5;
	align-self: flex-end;
}

.url-button-attachment:first-child {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
}
.url-button-attachment:last-child {
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
	border-bottom: 1px solid $primary;
}

.url-button-attachment {
	text-decoration: none;
	padding: $spacer;
	display: block;

	text-align: center;
	color: $primary;
	background: $white;
	border-top: 1px solid $primary;
	border-left: 1px solid $primary;
	border-right: 1px solid $primary;

	width: 100%;
	&:hover {
		text-decoration: none;
	}
}

.quick-replies-container.quick-replies-container--hidden {
	height: 0;
	padding: 0;
	transition: all 0.2s;
}

.quick-replies-container {
	padding-bottom: 10px;
	padding-top: 10px;
	width: 100%;
	background-color: $white;
	box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
	transition: all 0.2s;
}

.quick-replies {
	display: flex;
	flex-direction: row;

	opacity: 1;
	will-change: transform;

	user-select: none;
	cursor: grab;
	width: 100%;
	overflow: hidden;
}

.quick-replies--item {
	background: #ffffff;
	white-space: nowrap;
	border: 1px solid $primary;
	border-radius: 20px;
	color: $primary;
	padding: 8px;
	margin-right: 6px;
	margin-left: 6px;
	cursor: pointer;

	text-align: center;
}

.generic-attachment-container.generic-attachment-container__user {
	width: 70%;
	.generic-attachment {
		margin-left: 50px;
	}
}

.generic-attachment-container {
	position: relative;
	margin-top: 5px;
}

.generic-attachments {
	display: block;
}

.generic-attachment {
	display: flex;
	width: 100%;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	user-select: none;
	cursor: grab;
}

.generic-attachment-container.generic-attachment-container__agent .generic-attachment {
	overflow-y: hidden;
	overflow-x: auto;
	max-width: 100%;
}

.product_attachment {
	margin-top: 10px;
	margin-right: 50px;
	align-self: flex-end;
	border: 1px solid #d8d8d8;
	background: white;
	border-radius: 7px;
	//overflow: hidden;
	width: 200px;
	display: inline-block;
	vertical-align: top;
	transition: all 0.1s;
	margin-bottom: 50px;
	position: relative;

	&.convo_ended {
		margin-bottom: 10px;
	}
}

.product_attachment__img_container {
	overflow: hidden;
	width: 100%;
	padding-top: 100%;
	position: relative;
	display: block;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
}

.product_attachment.product_attachment__user {
	margin-right: 0;
	margin-left: 50px;
	align-self: flex-start;
}

.btn.product_attachment__create_order_btn {
	position: absolute;
	top: calc(100% + 8px);
	width: 100%;
	//box-shadow: 0 7px 15px rgb(10 10 10 / 10%);
	border-radius: 20px;
	display: flex;
	align-items: center;
	padding: 5px 8px;
	border-color: $blue-300;
	color: $primary;
	font-size: 14px;

	&:hover {
		border-color: $primary;
		background-color: rgba($blue-100, 0.1);
	}
}

.product_attachment.convo_ended .btn.product_attachment__create_order_btn {
	display: none;
}

.product_attachment__create_order_btn_icon {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	margin-right: 5px;
	background: $primary;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
}

.product_attachment__img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-bottom: 1px solid #d8d8d8;
	object-fit: cover;
}

.product_attachment__price {
	color: $text-muted;
	padding-left: 10px;
}

.product_attachment__btns {
	margin-top: 5px;
}
.product_attachment__btn {
	border-top: 1px solid #d8d8d8;
	display: flex;
	padding: 8px 10px;
	align-items: center;
	color: $black;
	justify-content: center;
	cursor: pointer;
	text-transform: capitalize;
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		color: $primary;
	}
}

a.product_attachment__title {
	display: block;
	padding: 8px 10px 0 10px;
	font-weight: 500;
	color: $black;
}

.product_attachment__subtitle {
	white-space: pre-wrap;
	padding: 0 10px;
	color: $text-muted;
	font-size: 14px;
	margin-bottom: 5px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.generic-attachment--element {
	display: flex;
	flex-direction: column;
	border: 1px solid #d8d8d8;
	background: white;
	border-radius: 7px;
	overflow: hidden;
	width: 220px;
	flex-shrink: 0;
	margin-right: $spacer;
	vertical-align: top;
	transition: all 0.1s;

	&:last-child {
		margin-right: 0;
	}

	.generic-attachment--element-img {
		width: 100%;
		height: 150px;
		object-fit: cover;
	}

	.url-button-attachment:first-child {
		border-radius: 0;
	}
	.url-button-attachment:last-child {
	}

	.url-button-attachment {
		padding: $spacer;
		display: block;
		padding-top: $spacer;
		padding-bottom: $spacer;
		text-align: center;
		border: none;
		border-top: 1px solid #d8d8d8;
		width: 100%;
	}
}

.generic-attachment--element.generic-attachment--element--edit {
	width: 270px;
	filter: brightness(1);
}

.generic-attachment--element.generic-attachment--element--fade {
	width: 270px;
	filter: brightness(0.8);
	cursor: pointer;
	&:hover {
		filter: brightness(1);
	}
}

.generic-attachment--element-title {
	padding: 0px 13px;
	margin-top: 13px;
	color: $black;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	white-space: normal;
	text-align: left;
}

.generic-attachment--element-title-input {
	padding: 13px $spacer 6px $spacer;
	color: $black;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	border: none;
	display: block;
	width: 100%;
	&:focus {
		outline: 0;
	}
}

.generic-attachment--element-subtitle {
	padding: 0px 13px;
	color: gray;
	margin-top: 5px;
	margin-bottom: 5px;
	white-space: normal;
	text-align: left;
}

.generic-attachment--element-subtitle-input {
	padding: 0 $spacer;
	display: block;
	color: gray;
	border: none;
	width: 100%;
	&:focus {
		outline: 0;
	}
}

.message_reactions {
	position: relative;
	display: flex;
	background: #fff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 35px;
	z-index: 1;
	bottom: 5px;
	padding: 1px 4px;
	align-items: center;
}

.message_reactions.message_reactions__user {
	margin-left: $spacer * 6;
}
.message_reactions.message_reactions__agent,
.message_reactions.message_reactions__dummy {
	margin-right: $spacer * 6;
}

.message_reactions__reaction {
	display: flex;
	align-items: center;
	position: relative;
	margin-right: $spacer;
	font-weight: 500;
	&:last-child {
		margin-right: 0;
	}
}

.message_reactions__reaction_counter {
	color: $primary;
	margin-left: $spacer * 0.3;
	font-size: $font-size-sm;
}

.message_container__user .message_actions__action .dropdown {
	right: unset;
	left: 0;
}

.detect_user_info_from_message__container {
	padding: $spacer;
	border-radius: 8px;
	position: absolute;
	// top: 100%;
	left: 0;
	z-index: $zindex-dropdown;
	background: #12171c;
	box-shadow:
		0 1px 8px rgba(0, 0, 0, 0.1),
		0 4px 32px rgba(0, 0, 0, 0.1),
		0 8px 64px rgba(0, 0, 0, 0.12);
	color: $white;
	white-space: nowrap;
	visibility: hidden;
	opacity: 0;
	display: none;
	user-select: none;
}
.detect_user_info_from_message__container__visible {
	visibility: visible;
	z-index: 50000;
	opacity: 1;
	display: block;
}

.detect_user_info_from_message__container__up {
	top: -113px;
}

.detect_user_info_from_message__container__down {
}

.detect_user_info_from_message__container__user {
	left: 0;
	right: auto;
}

.detect_user_info_from_message__container__agent {
	left: auto;
	right: 0;
}

.detect_user_info_from_message__btn_submit {
	margin-left: $spacer * 0.5;
}

.message_quick_replies {
	display: flex;
	flex-wrap: wrap;
}

.message_quick_reply {
	position: relative;
	padding: $spacer * 0.5 $spacer * 1.5;
	border: 1px solid $primary;
	margin-top: $spacer;
	color: $primary;
	border-radius: 5px;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.message_quick_reply.message_quick_reply__add_btn {
	user-select: none;
	cursor: pointer;
	border: 1px dashed $primary;
	margin-right: 0;
	margin-top: 0;
	margin-bottom: $spacer;
	&:hover {
		border: 1px dashed $blue-700;
		color: $blue-700;
	}
}

.message_quick_reply:hover {
	.message_quick_reply__btns {
		opacity: 1;
		z-index: 10;
	}
}
.message_quick_reply__btns {
	position: absolute;
	display: flex;
	align-items: center;
	top: -15px;
	right: -5px;
	opacity: 0;

	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	background: $white;
	&:hover {
		opacity: 1;
	}
}

.message_quick_reply__btn {
	cursor: pointer;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $gray-800;
	&:hover {
		color: $primary;
	}
}

.message_quick_reply__input {
	outline: none;
	border: none;
	padding: $spacer * 0.5 $spacer * 1.5;
	position: absolute;
	color: $primary;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
}

.message_quick_reply__input_hidden_span {
	visibility: hidden;
	height: 0;
	padding: $spacer * 0.5 $spacer * 1.5;
	white-space: pre;
	padding-left: $spacer * 3;
}

.message_quick_reply.message_quick_reply__editable {
	padding: 0;
	border: 1px solid $primary;
	margin: 0;
	margin-right: $spacer;
	margin-bottom: $spacer;
	color: $primary;
	border-radius: 5px;

	overflow: unset;
	white-space: unset;
	text-overflow: unset;

	position: relative;
	min-width: 50px;
	max-width: 100%;
	height: 34px;

	&:hover {
		border-color: $blue-700;
	}
}

.generic_template_button_handler {
	cursor: grab;
	display: flex;
	align-items: center;
	width: $spacer * 2;
	padding-right: $spacer * 0.5;

	opacity: 0.3;
	&:hover {
		opacity: 0.7;
	}
}

.generic_template_button_title {
	color: $primary;
	flex: 1;
	text-align: center;
}

.generic_template_button_title.generic_template_button_title__add {
	@include text-truncate();
	color: $text-muted;
	cursor: pointer;
	&:hover {
		color: $primary;
	}
}

.generic_template_button:first-child {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	border-radius: 0;
}

.generic_template_button:last-child {
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
}

.generic_template_button {
	text-decoration: none;
	background: $white;
	align-items: center;
	width: 100%;

	display: flex;
	padding: $spacer * 0.5;
	padding-top: $spacer;
	padding-bottom: $spacer;
	border: none;
	border-top: 1px solid #d8d8d8;
	width: 100%;
	&:hover {
		.generic_template_button__btn {
			opacity: 1;
		}
	}
}

.generic_template_button.generic_template_button__edit {
	display: block;
	padding: $spacer;
	position: relative;
	background: #f8f9ff;
}

.generic_template_button__btn {
	color: #a8a8a8;
	cursor: pointer;
	opacity: 0;
	&:focus {
		outline: 0;
	}

	&:hover {
		color: $primary;
	}
	margin-left: $spacer * 0.3;
}

.generic_template_button_edit_label {
	margin-top: $spacer;
	margin-bottom: $spacer * 0.5;
	&:first-child {
		margin-top: 0;
	}
}

.generic_template_button_edit {
	border: 1px solid #d8d8d8;
}

.generic_template_summary {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.generic_template_summary_img {
	border: 2px solid #d8d8d8;
	padding: 2px;
	border-radius: 5px;
	cursor: pointer;
	margin-right: $spacer;
	width: 40px;
	height: 30px;
	display: block;
	margin-top: $spacer;
}

.generic_template_summary_img.generic_template_summary_img__add {
	border: 2px dashed #d8d8d8;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #d8d8d8;
	font-weight: 500;
	&:hover {
		border-color: $primary;
		color: $primary;
	}
}

.generic_template_summary_img.generic_template_summary_img__active {
	border-color: $primary;
}

.generic_template__element_img {
	position: relative;
}

.generic_template__product_tag {
	position: absolute;
	bottom: 10px;
	background: #00000099;
	left: 10px;
	color: white;
	border-radius: 20px;
	padding: 2px 10px;
	font-size: 13px;
}

.generic_template__element_img__btns {
	position: absolute;
	top: 0;
	right: 0;

	position: absolute;
	display: flex;
	align-items: center;
	top: $spacer;
	right: $spacer;
	padding: 0 $spacer * 0.5;
	opacity: 1;

	z-index: 2;

	-webkit-box-shadow: 0px 0px 54px 27px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 54px 27px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 54px 27px rgba(0, 0, 0, 0.75);
	border-radius: 10px;
	background: $white;
}

.generic_template__element_img__btn {
	width: 26px;
	height: 26px;
	padding: $spacer * 0.6 $spacer * 0.6;
	color: #333;
	cursor: pointer;
	&:hover {
		color: $primary;
	}
	&:focus {
		outline: 0;
	}
}

.generic-attachment-navigator {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	width: 20px;
	font-size: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(245, 245, 245, 0.85);
	z-index: 999;

	&:hover {
		color: $gray-600;
	}

	&.generic-attachment-navigator--disabled {
		display: none;
	}

	&.generic-attachment-navigator--left {
		left: 2px;
	}

	&.generic-attachment-navigator--right {
		right: 2px;
	}
}

.dropdown.generic_template_search_product_dropdown {
	top: calc(100% + 1px);
	min-width: 250px;
	max-width: 250px;
	left: -35px;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
	display: flex;
	flex-direction: column;
	height: 265px;
	max-height: 265px;
}

.generic_template_search_product_input {
	padding-left: $spacer * 3;
	padding-right: $spacer * 3;
}

.generic_template_search_product_input_search_icon {
	position: absolute;
	color: $gray-600;
	top: 50%;
	left: $spacer * 2;
	transform: translateY(-50%);
}

.generic_template_search_product_input_loading_icon.spinner1 {
	position: absolute;
	top: 20px;
	right: $spacer * 2;
}

.generic_template_select_product_wrapper {
	position: absolute;
	top: $spacer;
	left: $spacer;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
}

.generic_template_img_overlay {
	position: absolute;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.5);
	align-items: center;
	justify-content: center;
	display: none;
}

.generic-attachment--element--edit:not(.generic-attachment--element--fade):hover .generic_template_img_overlay {
	display: flex;
}

.generic_template_img_trash_icon {
	color: $white;
	cursor: pointer;
	position: absolute;
	top: $spacer;
	right: $spacer;
	opacity: 0.85;

	&:hover {
		opacity: 1;
	}
}

.generic_template_img_btn {
	border-radius: 20px;
	display: flex;
	align-items: center;
	padding: $spacer * 0.5 $spacer * 2;
	cursor: pointer;
	border: 1.5px solid $white;
	background: transparent;
	color: $white;
	width: fit-content;
	opacity: 0.85;

	&:hover {
		opacity: 1;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.message_template_modal_box {
	font-size: 14px;
	height: 550px;
	display: flex;
	flex-direction: column;
}

.message_template_modal_box_head {
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	border: 1px solid $border-color;
	border-bottom: none;
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.message_template_modal_box_content {
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	border: 1px solid $border-color;
	border-top: none;
	flex: 1;
	overflow-y: scroll;
}

.message_template_modal_box_item {
	display: flex;
	align-items: center;
	padding: $spacer;
	border-bottom: 1px solid $border-color;
}

.message_template_modal_box_item_key {
	padding: 5px 8px;
	background-color: $gray-200;
	font-size: 13px;
	font-family: $font-family-monospace;
	line-height: 1;
	border-radius: 4px;
	margin-right: 5px;
	margin-bottom: 5px;

	&:last-child {
		margin-right: 0;
	}
}

.message_template_modal_box_item_img {
	border-radius: 5px;
	border: 1px solid $border-color;
	overflow: hidden;
	width: 40px;
	height: 40px;
	margin-top: 5px;
}

.message_template_modal_box_item_actions {
	margin-left: $spacer * 3;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	display: none;
	align-self: flex-start;
}

.message_template_modal_box_item:hover .message_template_modal_box_item_img {
	//display: none;
}

.message_template_modal_box_item:hover .message_template_modal_box_item_actions {
	display: flex;
}

.message_template_edit_modal_channel_type_img {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	object-fit: cover;
	margin-right: 5px;
}

.message_template_modal_content {
	display: flex;
	flex-direction: column;
	width: 450px;
	max-height: 880px;
	background-color: $white;
	z-index: 160;
	margin: auto;
	border-radius: 5px;
}

.message_template_modal_content_header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid $border-color;
	padding: $spacer * 1.5 $spacer * 2;
	flex-shrink: 0;
}

.message_template_modal_content_body {
	padding: 10px $spacer * 2;
	flex: 1;
	overflow: auto;
}

.message_template_modal_content_footer {
	display: flex;
	align-items: center;
	border-top: 1px solid $border-color;
	padding: 10px $spacer * 2;
	flex-shrink: 0;
}

.email_message_card {
	background-color: $white;
	border-radius: 8px;
	border: 1px solid $border-color;
	margin: 20px;
	margin-bottom: 0;
}

.email_message_card_header {
	padding: 10px 20px;
	padding-bottom: 8px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #f5f5f5;
}

.email_message_card_content {
	padding: 8px 20px;
}

.email_message_card_footer {
	padding: 10px 20px;
	display: flex;
	align-items: center;
	border-top: 1px solid #f5f5f5;
	flex-wrap: wrap;
	padding-bottom: 0;
}

.email_message_card_attachment {
	max-width: 250px;
	overflow: hidden;
	padding: 8px 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	display: flex;
	border-radius: 8px;
	background-color: $gray-100;
	display: flex;
	color: #333;

	&:hover {
		background-color: $gray-200;
	}
}

.email_message_card_attachment_icon {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
}

.facebook_comment_card {
	max-width: 500px;
	margin: 0 auto;
	background-color: $white;
	border-radius: 8px;
	border: 1px solid $border-color;
	margin-top: 20px;
}

.instagram_comment_card {
	width: 800px;
	max-width: 95%;
	height: 450px;
	max-height: 100%;
	display: flex;
	margin: 0 auto;
	border: 1px solid #00000044;
}

.facebook_comment_card_post {
	padding: $spacer * 2 $spacer * 3;
	border-bottom: 1px solid $border-color;
}

.instagram_comment_preview {
	flex: 3;
	display: flex;
	align-items: center;
	overflow: hidden;
	background: #ffffffad;
	flex-shrink: 0;
}

.instagram_comment_content {
	border-left: 1px solid #efefef;
	flex: 3;
	background: white;
	max-width: 350px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.facebook_comment_card_post_header {
	display: flex;
}

.instagram_comment_card_post_header {
	display: flex;
	height: 60px;
	border-bottom: 1px solid #efefef;
	padding: 10px 15px;
}

.facebook_comment_card_post_img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

.instagram_comment_card_post_img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

.facebook_comment_card_comments {
	padding: $spacer * 2 $spacer * 3;
}

.instagram_comment_card_comments {
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column-reverse;
	padding: 15px 0px 15px 20px;
}

.facebook_comment_message_wrapper {
	display: flex;
	margin-left: 35px;
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}

	&.first {
		margin-left: 0;
	}
}

.instagram_comment_message_wrapper {
	display: flex;
	margin-bottom: 15px;
}

.facebook_comment_message_wrapper.error .facebook_comment_message {
	background-color: #feecf0;
}

.facebook_comment_message {
	background-color: #e4f3fd;
	padding: 8px 15px;
	border-radius: 8px;
	margin-bottom: 3px;
	position: relative;
	white-space: pre-wrap;

	&.user {
		background-color: #f0f0f0;
	}
}

.instagram_comment_message {
	padding: 0 15px;
	word-break: break-word;
	overflow: hidden;
	margin-bottom: 3px;
	position: relative;
	// white-space: pre-wrap;
}

.message_quote_sender {
	display: flex;
	color: $gray-600;
	font-size: 13px;
	margin-left: 50px;
	margin-bottom: -2px;
	margin-top: 15px;

	&.internal-chat {
		margin-left: 20px;
		margin-top: 10px;
	}
}

.google_review_card {
	max-width: 500px;
	margin: 0 auto;
	background-color: $white;
	border-radius: 8px;
	border: 1px solid $border-color;
	margin-top: 20px;
}

.google_review_card_post {
	padding: $spacer * 2 $spacer * 3;
	border-bottom: 1px solid $border-color;
}

.google_review_card_comments {
	padding: $spacer * 2 $spacer * 3;
}

.google_review_location_name {
	font-size: 20px;
	font-weight: 500;
}

// custom for meail meassge in facebook_private_modal.js
.private_message_modal_body .message_editor.message_editor__html .message_editor__input.ql-container .ql-editor {
	// min-height: 160px;
}

// make from, to tag display fully lineheight, @ dont lose tail
.email_header .ti-tags .ti-tag span {
	line-height: 20px;
}

// css for multiple image
.message_container.has_grid.message_container__image {
	display: grid;
	grid-gap: 2px;
	margin-top: 8px;

	&.grid_columns_1 {
		grid-template-columns: repeat(1, 1fr);
	}

	&.grid_columns_2 {
		grid-template-columns: repeat(2, 1fr);
	}

	&.grid_columns_3 {
		grid-template-columns: repeat(3, 1fr);
	}
	&.grid_columns_3,
	&.grid_columns_2 {
		.message_image {
			position: relative;
			padding-top: 100%;
			margin: 0;

			.message_image__img {
				position: absolute !important;
				inset: 0;
				max-height: unset;
				border-radius: 4px;
				min-width: unset;
			}
		}
	}
}
