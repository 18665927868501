.search__item {
	user-select: none;
	flex-shrink: 0;
	width: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	overflow: hidden;

	border-radius: 4px;
	padding: $spacer * 0.6 $spacer;

	@include text-ellipsis;

	&:hover {
		background: #eee;
	}
}

.search__item.search__item__active {
	background: #eee8ff;
}

.search__item.search__item__suggestion {
	&:hover {
		color: $primary;
		.suggestion_prefix,
		.suggestion_suffix {
			color: $primary;
		}
	}
}

.suggestion_icon {
	padding-left: 5px;
	padding-top: 1px;
	background: #e0e0e0;
	border-radius: 50%;
	margin-right: $spacer;
	width: 26px;
	height: 26px;
	color: #333;
}

.suggestion_prefix {
	font-weight: 500;
}

.suggestion_suffix {
}

.search__user_info {
	font-size: 14px;
	@include text-ellipsis;
}

.search__user_username {
	font-size: 15px;
	overflow: hidden;
	display: flex;
}

.search__body {
	padding: $spacer;
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: auto;
	height: 100%;
	max-height: 500px;
	min-height: 250px;
}

.search__highlight {
	background: $yellow;
	color: $black;
}

.search__convo_text {
	overflow: hidden;
}

.search__loading {
	position: absolute;
	inset: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #0000000a;
}

.search__convo_avatar {
	display: block;
	position: relative;
}

.search__convo_channel {
	width: 16px;
	height: 16px;
	position: absolute;
	bottom: 0px;
	right: -5px;
	background: #fff;
	border-radius: 50%;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
}

.search__convos_channel_img {
	width: 12px;
	height: 12px;
}

// ---------------------------------

.search_modal.search_modal__show {
	opacity: 1;
	visibility: visible;
}

.search_count {
	display: inline-block;
	padding: 3px 6px;

	font-size: 0.9em;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 5px;
	background-color: #f2f2f2;

	margin-left: 6px;
	color: #929292;
}

.search_modal {
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: 38px;
	//	right: 0px;
	left: -100px;

	z-index: $zindex-dropdown;

	overflow: hidden;
	width: 420px;
	// Prevent Chrome on Windows from adding a focus outline. For details, see
	// https://github.com/twbs/bootstrap/pull/10951.
	outline: 0;
	// We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
	// gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
	// See also https://github.com/twbs/bootstrap/issues/17695

	align-items: center;
	justify-content: center;

	box-shadow: rgb(0 0 0 / 10%) -1px 5px 8px, rgb(0 0 0 / 10%) 7px 17px 16px, rgb(0 0 0 / 12%) 4px 33px 32px;
	// box-shadow: rgb(0 0 0 / 10%) 0px 1px 8px, rgb(0 0 0 / 10%) 0px 4px 32px, rgb(0 0 0 / 12%) 0px 8px 64px;
	//box-shadow: 0px 20px 9px rgb(0 0 0 / 10%), 1px 11px 10px rgb(0 0 0 / 10%);

	// box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	border-radius: 15px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	background: $white;
	border-right: 1px solid #d5d5d5;
	transition: all 150ms ease-out;
}

.search_modal_search_icon {
	z-index: $zindex-dropdown + 3;
	position: absolute;
	color: #ffffff88;
	left: 10px;
	top: 6px;
}

.header_bar_search_input_container {
	align-self: center;
	position: relative;
	width: 300px;
	max-width: 15vw;
	margin-right: 15px;
	transition: width 100ms ease-out;
}

.header_bar_search_input_container.header_bar_search_input_container__long {
	.search_modal_clear_btn {
		display: flex;
	}

	.search_modal_search_icon {
		color: #ffffffaa;
	}
}

.form-control.header_bar_search_input {
	background: #ffffff21;
	z-index: $zindex-dropdown + 2;
	width: 100%;
	position: relative;
	padding-left: 38px;
	border-radius: 18px;
	height: 28px;
	min-height: 28px;
	color: white;
	border: none;
	outline: none;
	&:active,
	&:focus,
	&:hover {
		background: #ffffff33;
	}
}

.search_modal_clear_btn {
	display: none;
	z-index: $zindex-dropdown + 3;
	position: absolute;
	color: #aaa;
	right: 5px;
	top: 3px;
	border-radius: 50%;
	background: transparent;
	cursor: pointer;
	padding: 5px;
	transition: all 150ms ease-out;
	align-items: center;
	justify-content: center;
	&:hover {
		color: #888;
	}
}
