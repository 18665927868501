.metric_box {
	border-radius: 5px;
	background: white;
	border-radius: 4px;
	box-shadow: rgb(35 46 60 / 4%) 0 2px 4px 0;
	border: 1px solid rgba(101, 109, 119, 0.16);
	margin-right: 20px;
}

.metric_box__header {
	padding: 15px 15px;
}

.p20 {
	padding: 20px;
}

.metric_box__icon {
	width: 20px;
	height: 20px;
}

.metric_box__content {
	width: 100px;
	margin-left: 20px;
}

.metric_box__label {
	font-size: 0.8rem;
	font-weight: 500;
	text-transform: uppercase;
	color: #656d77;
}

.metric_container {
	flex: 1;
	height: 100%;
	padding-top: 20px;
	overflow: auto;
	max-width: 1300px;
	margin: auto;
}

.metric_progress {
	height: 5px;
	width: 100%;
	position: relative;
	background: #e6e8e9;
	overflow: hidden;
	border-radius: 10px;
}

.metric_progress__bar {
	background: $primary;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 30%;
}

.metric_table thead tr {
	font-weight: normal;
	border-top: 1px solid rgba(101, 109, 119, 0.16);
	border-bottom: rgba(101, 109, 119, 0.16);
	color: #656d77;
	background: #f4f6fa;
}

.metric_table thead tr th {
	background: #f4f6fa;
	font-size: 11px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.04em;
	line-height: 1.6;
	color: #656d77;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 15px;
	padding-right: 15px;
}

.metric_table tr:hover {
	background: white;
}

.metric_table.table {
	border-left: 0;
	border-right: 0;
}

.metric_table td {
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 15px;
	vertical-align: middle;
	padding-right: 15px;
	font-size: 14px;
	min-width: 50px;
}

.metric_box__title {
	display: block;
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
}

.accounts {
	padding-top: 10px;
	border-right: 1px solid whitesmoke;
	flex-shrink: 0;
	overflow-y: scroll;
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.account_item.account_item__inactive {
	background: whitesmoke;
	&:hover {
		background: #f1f7ff;
	}
}

.account_item.account_item__selected {
	background: #dceaff;
	&:hover {
		background: #ccdaef;
	}
}

.account_item {
	cursor: pointer;
	border-bottom: 1px solid whitesmoke;
	display: flex;
	padding: 10px 20px;
	background: white;
	&:hover {
		background: #f1f7ff;
	}
}

.account_item__desc {
	flex: 1;
	overflow: hidden;
	margin-left: 15px;
}

.account_item__avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	background: whitesmoke;
	flex-shrink: 0;
	width: 46px;
	height: 46px;
	border-radius: 10px;
	border: 1px solid whitesmoke;
}

.account_item__name {
	font-weight: 500;
	@include text-truncate();
	margin-bottom: 3px;
}

.column {
	overflow: hidden;
}

.acc_credit_box {
	border: 1px solid #ffc10745;
	background: white;
	padding: 5px 10px;
	display: flex;
	border-radius: 20px;
	font-size: 13px;
	align-items: center;
	margin-right: 5px;
	margin-top: 10px;

	&:hover {
		cursor: pointer;
		background: #f5f5f5;
	}
}

.acc_sidebar {
	width: 350px;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	background: #f7f5f2;
	border-left: 1px solid rgba(166, 145, 113, 0.2);
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
}

.acc_sidebar_label {
	color: #777;
	display: flex;
	align-items: center;
	margin-top: 10px;
}
