.instant_noti__menu {
	user-select: none;
	cursor: default;
	color: $black;
	stroke: $black;

	display: flex;
	flex-direction: column;

	position: absolute;
	width: 340px;
	height: 500px;
	left: 10px;
	top: 50px;
	background: white;
	overflow: auto;

	border-radius: 8px;
	z-index: $zindex-dropdown;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	border: 1px solid #c6c6c6;
	// box-shadow: 0 0 0 1px #8898aa1a, 0 15px 35px #31315d1a, 0 5px 15px #00000014;
}

.instant_noti__noti {
	overflow: hidden;
	display: flex;
	min-height: 58px;
	align-items: center;
	// border-radius: 6px;
	cursor: pointer;
}

.instant_noti__menu_head {
	margin: 0 0 0 0;
	padding: 10px $spacer * 2 0 $spacer * 2;
	display: flex;
	background: #f7f5f4;
	justify-content: space-between;
	flex-shrink: 0;
}

.instant_noti__menu_head__title {
	font-size: 18px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.instant_noti__menu_body {
	overflow: auto;
	padding: 0 0 10px 10px;
	flex: 1;
}

.instant_noti__time {
	color: $gray-800;
	font-size: 12px;
}

.instant_noti__popup_noti-item {
	position: relative;
	user-select: none;
	margin-bottom: 0;
	background: white;
	backdrop-filter: blur(66px);
	width: 100%;

	box-shadow: $noti-shadow;

	padding: $spacer;
	border-radius: 8px;
	cursor: pointer;
	color: $black;
	transition: all 0.2s;
	margin-bottom: math.div($spacer, 2);

	&:last-child {
		margin-bottom: 0;
	}
}

.instant_noti__popup_noti-enter, .instant_noti__popup_noti-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
	opacity: 0;
	transform: translateX(-50px);
}

.instant_noti__popup_noti-leave-active {
	position: absolute;
}

.instant_noti__popup {
	position: fixed;
	z-index: $zindex-popover;
	bottom: 60px;
	left: 40px;
	width: 320px;
	color: unset;
	&:hover {
		color: unset;
		.instant_noti__popup_btns {
			display: flex;

			opacity: 1;
		}
	}
}

.instant_noti__icon {
	display: table; // so the height will be the heigh of children
	position: relative;
	margin-right: 10px;
}

.instant_noti__icon_type {
	position: absolute;
	right: 7px;
	bottom: -4px;
	padding: 3px;
	width: 25px;
	height: 25px;
	background: $yellow;
	border: 2px solid white;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	stroke: $white;
	color: $white;
}

.instant_noti__icon_type.instant_noti__icon_type__orange {
	background: $orange;
}

.instant_noti__icon_type.instant_noti__icon_type__red {
	background: $red;
}

.instant_noti__icon_type.instant_noti__icon_type__yellow {
	background: $yellow;
}

.instant_noti__icon_type.instant_noti__icon_type__blue {
	background: $blue;
}

.instant_noti__icon_type.instant_noti__icon_type__green {
	background: $green;
}

.instant_noti__popup_item_content {
	flex: 1;
}

.instant_noti__icon_type.order_noti__icon {
	width: 28px;
	height: 28px;
	border: 0px solid white;
	right: -10px;
	padding: 5px;
	bottom: -5px;
}

.instant_noti__noti.in_list_noti {
	padding: 5px $spacer;
	border-radius: 5px;
	&:hover {
		background: whitesmoke;
	}
}

.instant_noti__noti_body {
	font-size: 14px;
	line-height: 1.3;
}
