$avatar-size: 40px;

.recent_users {
	right: $spacer;
	height: 100%;
	bottom: $spacer * 2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: $white;
	border-left: 1px solid $section-border-color;
	width: 50px;
	align-items: center;
	padding-bottom: 20px;
	z-index: $zindex-recent-users;
}

.recent_users__icon {
	cursor: pointer;
	margin-top: $spacer;
	margin-bottom: $spacer;
	position: relative;
	color: $gray-600;
	&:hover {
		color: $black;
	}
}

.recent_users__icon.recent_users__icon__active {
	color: $primary;
}

.recent_users__user {
	padding-top: 7px;
	padding-bottom: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	position: relative;
	&:hover {
		.recent_users__popover {
			display: block;
		}
	}
}

.recent_users__user.recent_users__btn {
	position: relative;
	cursor: pointer;
	color: $gray-500;
	display: flex;
	align-items: center;
	justify-content: center;

	background: $gray-100;
	&:hover {
		color: $gray-900;
		background: $white;
	}
}

.recent_users__user_avatar {
	display: block !important;
	cursor: pointer;
}

.recent_users__popover {
	cursor: pointer;
	position: absolute;
	right: 50%;
	padding-right: $avatar-size * 0.5;
	top: 50%;
	transform: translateY(-50%);
	display: none;
	z-index: 80;
	max-width: 350px;
}

.recent_users__popover.recent_users__popover__show {
	display: block;
}

.recent_users__popover__arrow {
	position: absolute;
	right: -2px;
	top: 50%;
	background: $white;
	transform: rotate(45deg) translateY(-50%);
	width: 16px;
	height: 16px;
	border-radius: 4px;
	box-shadow: $modal-shadow;
}

.recent_users__popover__arrow_cover {
	position: absolute;
	right: 0;
	top: 0;
	background: #fff;

	width: 16px;
	height: 100%;
	border-radius: 10px;
}

.recent_users__popover__content {
	position: relative;
	margin-right: $spacer * 2;
	background: white;
	box-shadow: $modal-shadow;
	border-radius: 10px;
	padding: $spacer $spacer * 2 $spacer $spacer;
}

.recent_users__popover__content_content {
	background: #fff;
	z-index: 1000000;

	width: 100%;
}

.recent_users__user_name {
	font-weight: bold;
	white-space: nowrap;
	max-width: 400px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.recent_users__user_message {
	white-space: nowrap;
	max-width: 400px;
}

.recent_users__user {
	transition: all 250ms;
}

.recent_users__user-enter, .recent_users__user-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
	opacity: 0;
	transform: scale(0.2);
}

.recent_users__user-leave-active {
	position: absolute;
}

.recent_users__user__more {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: #00000070;
	border-radius: 50%;
	color: $white;

	display: flex;
	align-items: center;
	justify-content: center;
}

.recent_users__container {
	display: flex;
	flex-wrap: wrap-reverse;
	flex-direction: column;
	transition: opacity 150ms;
}

.recent_users__unread {
	z-index: 2;
	right: 0;
	bottom: 0;
	border-radius: 50%;
	position: absolute;
	background: $red;
	width: 14px;
	height: 14px;
	border: 2px solid $white;
}
