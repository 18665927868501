.toaster {
	display: flex;
	align-items: center;
	padding: 10px 15px;
	box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 8px 10px -4px;
	border-radius: 5px;
}
.toaster__content {
	padding: 2px 15px;
	min-width: 300px;
	width: 100%;
}
.toaster__icon_status__success {
	background-image: url('../img/success.svg');
	background-repeat: no-repeat;
}
.toaster__icon_status__warning, .toaster__icon_status__hellobar {
	background-image: url('../img/warning.svg');
	background-repeat: no-repeat;
}
.toaster__icon_status__error {
	background-image: url('../img/error.svg');
	background-repeat: no-repeat;
}
.toaster__border__success {
	background: #2FA84F;
}
.toaster__border__warning, .toaster__border__hellobar {
	background: #F3AA18;
}
.toaster__border__hellobar {
	white-space: nowrap;
}
.toaster__border__error {
	background: #EA3D2F;
}
.toaster__title {
	font-weight: 500;
	color: #FFFFFF;
}
.toaster__descrption {
	font-weight: nomal;
	color: #FFFFFF;
}
.toaster__icon_status {
	width: 25px;
	height: 20px;
}
.toaster__iconclose {
	color: #FFFFFF;
	cursor: pointer;
	width: 20px;
	height: 20px;
}
