.detail_rule__location {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 120px;

	margin-right: $spacer * 0.5;
	margin-bottom: $spacer * 0.5;

	background: $yellow-100;
	padding: 0 $spacer * 0.5;
	display: flex;
	align-items: center;
	border-radius: 4px;
}

.location_picker {
}

.location_picker__locations {
	display: flex;
	flex-wrap: wrap;
}

.location_picker__location {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 230px;

	margin-right: $spacer * 0.5;
	margin-bottom: $spacer * 0.5;

	background: $yellow-100;
	padding: 0 $spacer * 0.5;
	display: flex;
	align-items: center;
	border-radius: 4px;
}

.location_picker__remove_location_btn {
	margin-left: $spacer * 0.5;
	cursor: pointer;
	stroke: $gray-600;
	outline: none;
	&:hover {
		stroke: $black;
	}
}

.location_picker__empty_img {
	width: 150px;
	opacity: 0.4;
}
