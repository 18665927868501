.convo_tag__delete {
	opacity: 0.6;
	&:hover {
		opacity: 1;
	}
}

.convo_tag__container {
	background: white;
	margin: 5px 5px 5px 0;
	border-radius: 4px;
	&:only-child {
		margin: 0 5px 0 0;
	}
}

.user_info_field_tags {
	.convo_tag {
		margin-bottom: 2px;
		margin-top: 0;
		padding: 2px 5px;
		font-size: 15px;
		border-radius: 4px;
		font-weight: 400;
	}

	.convo_tag.convo_tag__add_btn {
		border: 1px dashed $gray-500;
		color: $gray-500;
		line-height: 16px;
		&:hover {
			border: 1px dashed $gray-800;
			color: $gray-800;
		}
	}
}

.convo_tag {
	margin: 5px 5px 5px 0;
	display: inline-block;
	padding: 0px 5px;
	border-radius: 15px;
	background: #FFFFFF;
	white-space: nowrap;
	color: #555;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	cursor: pointer;
	user-select: none;
}

.convo_tag__title {
	white-space: nowrap;
	overflow: hidden;
	max-width: 150px;
	text-overflow: ellipsis;
}

.convo_tag.convo_tag__add_btn {
	border: 1px dashed $gray-600;
  color: $gray-800;
  line-height: 16px;
	&:hover {
		border: 1px dashed $black;
		color: $black;
	}
}

.convo_tag.add_label_btn {
  background-color: $gray-200;
  color: $gray-700;
  cursor: pointer;
  height: 20px;

  &:hover {
    background-color: $gray-300;
  }
}

.convo_tags {
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
}

.dropdown.convo_header__tag_dropdown__full {
	left: 0;
}

.convo_header__tag_dropdown_item {
	padding: 0 $spacer;
	display: flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		background: #E8F0FF;
	}
}

.convo_tags__more {
	position: relative;
	display: inline-block;
	line-height: 18px;

	.convo_tags__dropdown {
		cursor: default;
		display: none;
		position: absolute;
		top: 80%;
		right: 0;
		background: $white;
		box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
		padding: $spacer;
		z-index: 10;
	}

	&:hover {
		.convo_tags__dropdown {
			display: block;
		}
	}
}

.tag {
  display: inline-block;
  padding: 0 0.5rem;
  border-radius: 4px;
  line-height: 1.5;
  font-size: 13px;
  background: #FFFFFF;
  border: 1px solid transparent;
  white-space: nowrap;
  color: #555;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.tag--lg {
    padding: 2px 8px;
  }
}

.tag.tag--primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary
}

.tag.tag--warning {
  background: $warning;
  border-color: $warning;
}

.tag.tag--success {
  background: $success;
  border-color: $success;
  color: #333;
}

.tag.tag--danger {
  background: $danger;
  border-color: $danger;
  color: #fff;
}

.tag.tag--secondary {
  background: $secondary;
  border-color: $secondary;
  color: #fff;
}

.tag.tag--primary.tag--light {
  color: rgb(41, 116, 249);
  background: rgb(238, 244, 255);
  border-color: rgb(191, 213, 253);
}

.tag.tag--warning.tag--light {
  color: rgb(253, 174, 4);
  background: rgb(255, 249, 235);
  border-color: rgb(254, 231, 180);
}

.tag.tag--success.tag--light {
  color: rgb(3, 137, 47);
  background: rgb(235, 246, 238);
  border-color: rgb(179, 220, 193);
}

.tag.tag--danger.tag--light {
  color: rgb(252, 13, 6);
  background: rgb(255, 236, 235);
  border-color: rgb(254, 182, 180);
}


.tag.tag--secondary.tag--light {
  background: rgba(108, 117, 125, 0.1);
  border-color: rgba(108, 117, 125, 0.7);
  color: $secondary;
}
