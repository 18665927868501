.live_container {
	padding-top: $spacer * 2;
	padding-left: $spacer * 2;
	padding-right: $spacer * 2;
	flex: 1;
	height: 100%;
	overflow: auto;
}

.live_box {
	border: 1px solid #eeeeee;
	border-radius: 12px;
}

.live_box__empty_body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: $spacer * 4 0;
}

.live_box__header {
	border-bottom: 1px solid #eeeeee;
	display: flex;
	justify-content: space-between;
	padding: $spacer $spacer * 2;
}

.live_box__row {
	margin: 0 $spacer * 2;
	padding: $spacer 0;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #eeeeee;
	align-items: center;
	&:last-child {
		border-bottom: none;
	}
}

.live_box__row.live_box__row__clickable {
	cursor: pointer;
}

.live_box__col {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-left: $spacer;
	&:first-child {
		margin-left: 0;
	}
}

.live__total {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.live_view__row.live_view__row__hover {
	cursor: pointer;
	&:hover {
		background: $gray-200;
	}
}

.live_view__row.live_view__row__selected {
	background: $gray-400;
}

.live_view__row {
	margin: 0 $spacer * 2;
	padding: $spacer 0;

	justify-content: space-between;
	border-bottom: 1px solid #eeeeee;
	align-items: center;
	&:last-child {
		border-bottom: none;
	}
}

.live_view__col {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-left: $spacer;
	margin-top: $spacer;
	margin-bottom: $spacer;
	max-width: 400px;
	min-width: 20px;
}

.live_view__col.live_view__col__fullname {
	display: flex;
	align-items: center;
}

/*
border: 1px solid #D8D8D8;
		background: #fff;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
*/

td.live_dropdown_container {
	position: relative;
	overflow: unset;
}

.live_dropdown {
	position: absolute;
	min-width: 200px;
	max-width: 100%;
	overflow: hidden;

	z-index: 2;
	top: 100%;
	right: 0;

	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
	// box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06), 0px 4px 32px rgba(0, 0, 0, 0.06), 0px 8px 64px rgba(0, 0, 0, 0.08);

	border-radius: 8px;
	border-top-right-radius: 0;
}

.live_dropdown.live_dropdown__viewing {
	width: 100%;
}

.live_dropdown__item {
	font-weight: normal;
	padding: $spacer * 0.5 $spacer;
	display: block;
	align-items: center;
	cursor: pointer;
	background: $white;
	position: relative;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding-top: $spacer * 0.6;
	}

	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding-bottom: 0.6 * $spacer;
	}

	&:hover {
		color: white;
		background: $primary; // #E8F0FF;
		.convo_header__dropdown_icon {
			color: white;
		}
	}
}

.live_dropdown__item.live_dropdown__item__no_select {
	&:hover {
		color: unset;
		background: $white; // #E8F0FF;
	}
}

.live_dropdown__item.live_dropdown__item__viewing {
	display: flex;
}

.live_dropdown__item__loading {
	position: absolute;
	top: 15px;
	right: 20px;
}

.live_filter__button_x {
	color: $gray-500;
	stroke: $gray-500;
	cursor: pointer;
	&:hover {
		stroke: $black;
		color: $black;
	}
}

.live_filter__header {
	color: $gray-700;
	display: flex;
	align-items: center;
	font-weight: 500;
}

.live_filter__header.live_filter__header__clickable {
	cursor: pointer;
	&:hover {
		color: $black;
	}
}

.live_view__user_name {
	color: $black;
}

.live_view__user_email {
	color: $gray-600;
}

.live_filter__viewing_filter_text {
	color: $primary;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	max-width: 150px;
}

.live_filter__orderby {
	display: flex;
	position: relative;
	cursor: pointer;
	align-items: center;
	color: $gray-600;
	stroke: $gray-600;
	&:hover {
		color: $black;
		stroke: $black;
	}
}

.live_view__header {
	padding: $spacer 0;
	display: flex;
	justify-content: space-between;
}

.live_view__empty {
	display: block;
	margin: $spacer * 4 auto;
}

.live_view__loading {
	display: block;
	margin: $spacer * 4 auto;
}

.live_view__table {
	width: 100%;
	overflow: auto;
}

.live_view__viewing_url {
	color: $gray-600;
	font-size: smaller;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.live_view__viewing_title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.live_number {
	color: $black;
	padding: 0 $spacer * 0.5;
	border-radius: 4px;
	transition: color 0.5s ease;
	transition: background-color 0.5s ease;
	background: $white;
}

.live_number.live_number__unknown {
}

.live_number.live_number__increase {
	background-color: $green;
	color: $white;
}

.live_number.live_number__decrease {
	color: $white;
	background-color: $red;
}

.live_number.live_number__immutable {
}

.live_number.live_number__total {
	font-size: 48px;
	padding: $spacer * 2;
}

.live_number.live_number__increase.live_number__total {
}

.live_view__result_count {
	align-items: center;
	display: flex;
}

.live_view__order_by {
	align-items: center;
	display: flex;
}

.live_view__user_online {
	flex-shrink: 0;
	display: inline-block;
	align-items: center;
	color: #00a808;
	text-transform: lowercase;
}

.live_view__upgrade {
	display: flex;
	align-items: center;
	background: linear-gradient(5.28deg, #f98006 34.25%, #fa681f 65.75%);
	height: 60px;
	color: $white;
	justify-content: center;
}
