.radio {
	display: flex;
	position: relative;
	margin-bottom: 8px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
  user-select: none;
  width: max-content;
}

.radio__input {
	opacity: 0;
  width: 0;
  height: 0;
}

.radio__checkmark {
	position: relative;
	border: 1px solid #5f6369;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio:hover .radio__input ~ .radio__checkmark {
	border-color: #2196f3;
}

.radio__input:checked ~ .radio__checkmark {
	border-color: darken($primary, .2);
}

.radio__checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.radio__input:checked ~ .radio__checkmark:after {
	display: block;
}

.radio__checkmark:after {
	border-radius: 50%;
  background: $primary;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.radio__label {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

// radio button
.radio-btn-group {
  display: flex;
}

.radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-500;
  padding: $spacer*0.5;
  border: 1px solid $border-color;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
  margin-bottom: 0;
  line-height: 1;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:not(:last-child) {
    border-right: none;
  }

  input[type="radio"] {
    display: none
  }

  &.radio-button--active,
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: $gray-700;

    .radio-button__icon {
      stroke: $gray-700;
    }
  }

  &.radio-button--no-border-radius {
    border-radius: 0;
  }

  &.radio-button--primary {
    border-color: $primary;
    color: $primary;
    background-color: #fff;
  }

  &.radio-button--primary:hover {
    border-color: $blue-700;
    color: $blue-700;
    background-color: #fff;
  }
  &.radio-button--primary.radio-button--active {
    border-color: $primary;
    color: #fff;
    background-color: $primary;
		.radio-button__icon {
			stroke: $white;
		}
  }
}

.radio-button__icon {
  width: 1rem;
  height: 1rem;
  stroke: $gray-500;
}
