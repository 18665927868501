@use 'sass:math';

$container-width: 960px;

.billing-section {
	display: flex;
	justify-content: space-between;
}

.bill__form {
	width: calc((100% - 20px) / 2);
	padding: $spacer * 2;
	margin-right: $spacer * 2;
}

.bill__form__head {
	display: flex;
	width: 100%;
	margin-bottom: 0.5rem;
}

.bill__form__head__title {
	font-size: 1rem;
	font-weight: 500;
}

.bill__form__head__action {
	margin-left: auto;
}

.bill__form__plan {
	padding: 0.5rem 1rem;
	border-radius: 4px;
	border: 1px solid $border-color;
	font-size: 1.125rem;
	width: fit-content;
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	color: $gray-700;
}

.bill__form__plan__icon {
	width: 24px;
	height: 24px;
	margin-right: 0.5rem;
}

.bill__form__agents {
	margin-bottom: 1.5rem;
}

.agents__box {
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
}

.agents__minus,
.agents__plus {
	cursor: pointer;
	user-select: none;
	stroke: $gray-500;
	fill: $gray-500;

	&:hover {
		stroke: $black;
		fill: $black;
	}
}

.agent__input {
	max-width: 80px;
	border: 2px solid $border-color;

	&:active,
	&:focus,
	&:hover {
		border-color: $border-color;
	}
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.agent__modify {
	display: flex;
	flex-direction: column;
	margin-left: 0.25rem;
	height: 100%;
	justify-content: space-between;
}

.cycles-container {
	margin-top: 0.5rem;
}

.bill__invoice {
	width: calc((100% - 20px) / 2);
	padding: $spacer * 2;
	max-height: 600px;
}

.bill__invoice__loading {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;

	.loading {
		background-color: transparent;
	}
}

.bill__invoice__iframe {
	transform: scale(0.6);
	transform-origin: 0 0;

	&.frame--big {
		transform: scale(0.75);
	}
}

.bill__invoice__head {
	font-weight: 500;
	margin-bottom: 0.5rem;
}

.bill__invoice__unit-price {
	font-size: 2.25rem;
	font-weight: 200;
	margin-bottom: 1rem;
	color: $gray-700;
}

.bill__invoice__unit-price__unit {
	margin-left: 0.5rem;
	font-size: 0.875rem;
	font-weight: 400;
}

.bill__invoice__money {
	margin-top: auto;
	width: 60%;
	margin-left: auto;
}

.bill__invoice__money__total,
.bill__invoice__money__discount {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.25rem;
}

.bill__invoice__money__subtotal {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 0 0;
	border-top: 1px solid $border-color;
	font-weight: 500;
	font-size: 1.125rem;
}

.subscription-root {
	display: flex;
	margin-top: 0;
	width: 100%;
	min-height: 100%;
	background: $gray-100;
}

.subscription-page {
	width: $container-width;
	margin: 0 auto;
}

.cr-plan {
	margin-bottom: 2rem;
}

.cr-plan__head {
	display: flex;
	align-items: flex-end;
	margin-bottom: 0.5rem;
	width: 100%;
}

.cr-plan__head__name {
	font-weight: 500;
}

.subscription-label__action {
	margin-left: auto;
	font-weight: normal;
	font-size: $font-size-base;
}

.subscription-label {
	font-weight: 500;
	font-size: $font-size-lg;
	margin-top: $spacer * 2;
	margin-bottom: $spacer;
	display: flex;
	align-items: flex-end;
}

.subs__content {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.credit_box {
	margin-top: 10px;
	padding: 10px 15px;
	background-color: #fff;
	border-radius: 7px;
	position: relative;
	border: 2px solid $border-color;
	cursor: pointer;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.credit_balance_box {
	margin-top: 10px;
	padding: 10px 15px;
	background-color: whitesmoke;
	border-radius: 7px;
	position: relative;
	align-items: center;
	overflow: hidden;
	width: 200px;
}

.credit_balance_box_balance {
	font-size: 20px;
	font-weight: 50px;
	margin-top: 5px;
	font-weight: 500;
}

.credit_box_name {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 13px;
	color: #666;
	flex: 1;
	@include text-truncate();
}

.credit_box_credit {
}

.credit_box:hover {
	border-color: #555;
}

.credit_box.credit_box__active {
	border-color: $primary;
}

.payment_box {
	padding: $spacer * 3;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 7px;
	position: relative;
	box-shadow: $box-shadow;
}

.payment_box.payment_box__one_third {
	width: math.div($container-width - $spacer * 4, 3);
}

.subscription__logo {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
}

.subscription__logo__img {
	width: 32px;
	height: 32px;
	stroke: $gray-500;

	&.logo--current {
		border-radius: 50%;
		background-color: $green;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.subscription__name {
	margin: $spacer * 2 0;
	font-size: $font-size-lg;
	font-weight: bold;
	text-align: center;
}

.subscription__price {
	font-weight: bold;
	width: 100%;
	text-align: center;
	position: relative;
}

.subscription__sub_price {
	color: $gray-600;
	text-align: center;
	position: relative;
	margin-bottom: $spacer * 2;
}

.subscription__price__money {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.subscription__plan_items {
	width: 100%;
	text-align: left;
}

.subscription__plan_item {
	display: flex;
	width: 100%;
	align-items: center;

	margin-top: $spacer * 0.5;
	&:first-child {
		margin-top: $spacer * 2;
	}
}

.subscription__plan_item__icon {
	width: 16px;
	height: 16px;
	margin-right: $spacer;
	stroke: $gray-500;
}

.icon--active {
	stroke: $success;
}

.icon--black {
	stroke: $black;
}

.invoice-statistic-box {
	margin-bottom: 1.5rem;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.invoice__stat {
	width: calc((100% - 60px) / 4);
	margin-right: $spacer * 2;
	padding: $spacer * 2;
	text-align: center;

	&:last-child {
		margin-right: 0;
	}
}

.invoice__stat__head {
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 0.75rem;
}

.invoice__stat__content {
	font-size: 2.5rem;
	color: $gray-700;
	font-weight: 300;
	line-height: 1;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	&.content--small {
		font-size: 1.75rem;
	}
}

table.invoices__table {
	border: none;

	tr:last-child td {
		border-bottom: none;
	}

	thead tr:hover {
		background: transparent;
	}
}

.invoices__table thead th,
.invoices__table tbody td {
	width: auto;
	padding: 12px 8px;
	vertical-align: middle;
}

.invoices__table thead th {
	font-weight: 500;

	&.money {
		text-align: right;
	}
}

.invoices__table tbody td.money {
	font-size: 18px;
	font-weight: 300;
	text-align: right;
}

.invoice-detail {
	position: relative;
	padding-top: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.invoice-detail__bg {
	min-width: 600px;
	padding-top: 0;
}

.invoice-detail__action {
	background-color: #fff;
	padding: 0.5rem 0;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	display: flex;
	flex: 1;
}

.invoice-detail__bank {
	background-color: $gray-200;
	padding: 12px;
	font-size: 14px;
	border-radius: 3px;

	code {
		font-size: 14px;
	}
}

.invoice-detail__container {
	max-height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
}

.payment__dropdown {
	align-items: center;
	width: 100%;
}

.cycle__radio {
	margin-bottom: $spacer * 0.5;

	&:last-child {
		margin-bottom: 0;
	}
}

.subscription_info_card {
	padding: 10px 15px;
	border-radius: 8px;
	border: 1px solid $border-color;
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&.danger {
		background-color: $danger;
		color: $white;
	}

	&:first-child {
		margin-right: 40px;
	}
}

.subscription_info_card_title {
	font-weight: 500;
	font-size: 20px;
}

.subscription_info_card_subtitle {
	font-size: 14px;
	height: 42px; // only display 2 line
	@extend .text__muted;
}

.subscription_info_card_payment_status {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 14px;

	&.done {
		color: $success;
	}
	&.warning {
		color: $warning;
	}
}

.subscription_info_card_process_section {
	font-size: 14px;
	display: flex;
	flex-direction: column;
	height: 35px;

	.progress {
		height: 10px;
		margin-top: auto;
	}
}

.subscription_info_card_price {
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	color: $gray-600;

	&.success {
		color: $success;
	}
}

.subscription_info_card_cta_section {
	margin-top: 15px;
	display: flex;
	justify-content: flex-end;

	.btn {
		font-weight: 500;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.subscription_info_card.danger {
	.subscription_info_card_price {
		color: $white;

		&.success {
			color: $white;
		}
	}

	.subscription_info_card_process_section .progress-bar {
		background-color: $white;
	}

	.subscription_info_card_cta_section .btn.btn__white {
		color: $danger;
		border-color: transparent;
	}

	.subscription_info_card_cta_section .btn.btn__outline_white:hover {
		color: $danger;
	}
}

.payment_modal_qr_card {
	width: 200px;
	border: 1px solid $border-color;
	flex-shrink: 0;
	margin-right: 20px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 15px;
}

.payment_modal_bank_info_card {
	flex: 1;
	background-color: whitesmoke;
	padding: 10px 15px;
	border-radius: 8px;
}

.payment_modal_note_container {
	width: 100%;
	margin-top: 20px;
	border-radius: 8px;
	background-color: #fff6e3;
	padding: 15px;
}

.payment_modal_edit_amount_actions {
	display: flex;
	position: absolute;
	top: 50%;
	right: 10px;
	line-height: 1;
	transform: translateY(-50%);
}
