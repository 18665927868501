$convos-header-height: 45px;

.convos {
	min-width: 200px;
	max-width: 450px;
	position: relative;
	flex: 2;
	display: flex;
	flex-direction: column;
}

.convos__convo_name__unread {
	font-weight: 500;
}

.convos__convo_info__unread {
	opacity: 1;
}

.convos__tags {
	display: flex;
	margin-top: 3px;
	align-items: center;
	.convo_tag {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.internal_convos__name {
	color: $black;
	overflow: hidden;
}

.internal_convos__subject {
	font-size: 13px;
	flex: 1;
	color: $gray-600;
	overflow: hidden;
	@include text-truncate();
}

.convos__subject {
	font-size: 14px;
	flex: 1;
	opacity: 0.7;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.convos__subject.convos__subject__typing {
	opacity: 1;
}

.convos__subject.convos__subject__byuser {
	opacity: 1;
	font-weight: 500;
}

.convos__subject.convos__subject__selected {
	.text__danger {
		color: white !important;
	}
}

.convos__convo__completed {
	.convos__subject.convos__subject__byuser {
		opacity: 0.7;
		font-weight: 400;
	}
}

.convos__list {
	background: white;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.convos__list_syncing {
	position: absolute;
	top: 45px;

	left: 0;
	height: 18px;
	font-size: 12px;
	z-index: 3;
	color: $white;
	width: 100%;
	background: $secondary;
	text-align: center;
	transition: all 150ms ease-out;
	visibility: visible;

	&.sync_error {
		background-color: $danger;
	}
}

.convos__list_syncing.convos__list_syncing__hidden {
	opacity: 0;
	visibility: hidden;
}

.convos__list_unread_badge {
	position: absolute;
	top: 55px;
	left: 0;
	height: 32px;
	width: 60%;
	left: 20%;
	font-size: 15px;
	z-index: 3;
	border-radius: 50px;
	color: $white;
	background: $danger;
	cursor: pointer;
	color: $white;

	text-align: center;
	transition: all 150ms ease-out;
	visibility: visible;
	padding: 3px 10px;
	@include text-truncate();
	box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
	border: 2px solid #cd1f12;
}
.convos__list_unread_badge:hover {
	// border: 2px solid #ff5f53;
	background: #cd1f12;
}

.convos__list_unread_badge.convos__list_unread_badge__hidden {
	opacity: 0;
	top: 0;
	visibility: hidden;
}

.convos__list_ul {
	padding-left: 0;
}

.convos__delimiter {
	align-items: center;
	flex-shrink: 0;
	display: flex;

	margin-top: 0;
	position: relative;
	user-select: none;
	-webkit-user-select: none;

	padding: 0 $spacer 0 $spacer;
	background: white;
	flex-direction: row;
	font-weight: 500;
	position: relative;

	font-size: 16px;
	/* padding-left: 12px; */
	padding-top: 10px;
	padding-bottom: 5px;
	/* color: gray; */
	cursor: default;
	background: whitesmoke;
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
}

.convos__convo.convos__convo__directory {
	background: #fffced;
}

.convos__convo {
	align-items: center;
	flex-shrink: 0;
	display: flex;
	margin-top: 10px;
	cursor: pointer;

	color: $black;
	margin-top: 0;
	position: relative;
	user-select: none;
	-webkit-user-select: none;

	padding: 0 $spacer 0 $spacer * 2;
	margin: 0;

	height: 80px; // remember to change src/activities/convos.ITEMHEIGHT:7
	flex-direction: row;
	position: relative;
}

.convos__avatar_avt {
	width: 100%;
	height: 100%;
	position: relative;
}

.convos__convo.convos__convo__internal {
	height: 60px; // remember to change src/internal_chat/list_chat.vue:5
	align-items: center;
	padding: 0 20px;

	&:hover {
		background: #e2e8ff;
	}
}

.convos__convo__internal__all {
	&:hover {
		background: #e2e8ff;
	}
}

.convos__avatar.convos__avatar_disabled {
	opacity: 0.3;
	pointer-events: none;
}
.convos__convo.convos__convo__disabled:hover {
	.convos__convo__free {
		opacity: 1;
	}
}
.convos__convo__free {
	display: none;
	align-items: center;
	opacity: 0;
	position: absolute;
	top: -10px;
	z-index: 100;
	background: white;
	border-radius: 10px;
	left: 20px;
	padding: 5px;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
}

.convos__info_container.convos__info_container__disabled {
	opacity: 0.3;
	user-select: none;
	pointer-events: none;
}

.convos__info_container {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
	position: relative;
}

.convos__time {
	white-space: nowrap;
	display: flex;
	align-items: center;
	opacity: 0.7;
	font-size: 14px;
}

.convos__convo.convos__convo__completed {
	background-color: #eaeaea;
	.convos__channel {
		background: #eaeaea; // same as parent
	}

	.convos__name {
		opacity: 0.7;
	}
}

li.convos__convo.convos__convo__selected {
	color: $white;
	background-color: $secondary;
	.convos__channel {
		background: $secondary; // same as parent
	}
}

.convos__convo.convos__convo__selected.convos__convo__checked {
	background-color: #b9d5ff;
	.convos__channel {
		background: #b9d5ff; // same as parent
	}
}

.convos__convo.convos__convo__checked {
	background-color: #d2e4ff;
	.convos__channel {
		background: #d2e4ff; // same as parent
	}
}

.convos__channel_img {
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

.convos__channel {
	width: 24px;
	height: 24px;
	position: absolute;
	bottom: -3px;
	right: -3px;
	background: #fff;
	border-radius: 50%;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
}

.convos__avatar {
	height: 54px;
	width: 54px;
	// align-self: flex-start;
	display: flex;
	align-items: center;
	justify-content: center;
	// vertical-align: top;
	position: relative;
	margin-right: 15px;
}

.convos__line {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	// small emoji in convos line
	img.emoji {
		width: 16px;
		height: 16px;
	}
}

.convos__name {
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;
	overflow: hidden;
}
.convos__name.convos__name__byuser {
	font-weight: 500;
}

.load-more {
	background: linear-gradient(transparent, white);
	position: absolute;
	top: 80%;
	text-align: center;
	width: 100%;
	padding: 20px 0 10px 0;
	z-index: 1;
}

.load-more.load-more-message {
	top: 5%;
}

.load-more .spinner {
	border-left: 2px solid rgba(52, 152, 220, 0.15);
	display: inline-block;
	width: 18px;
	height: 18px;
	border-right: 2px solid rgba(52, 152, 220, 0.15);
	border-bottom: 2px solid rgba(52, 152, 220, 0.15);
	border-top: 2px solid rgba(52, 152, 220, 0.8);
}

.convos__header {
	border-bottom: 1px solid $section-border-color;
}

.convos__header__main {
	background: $white;
	flex-shrink: 0;
	display: flex;
	// flex-direction: column;
	height: 63px;
	overflow: hidden;
	flex-grow: 0;
	position: relative;
	z-index: 4; // higher than unread badge
}

.convos__search_input {
	display: block;
	width: 100%;
	border: 0px;
	// background: red;
	padding: 3px 1px 3px 25px;
	&:active,
	&:focus {
		outline: none;
	}
}

.convos_header__button {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: $blue-500;
	&:hover {
		color: $blue-700;
	}
}

.convos__avatar_avt {
}

.convos__avatar_avt.convos__avatar_avt__hidden {
	display: none;
}
.convos__avatar_check {
	border: 2px solid $gray-500;
	width: 20px;
	height: 20px;
	padding: 4px 3px 2px 3px;
	border-radius: 4px;
	align-items: center;
	justify-content: center;
	color: white;

	background-color: white;
	display: flex;
	&:hover {
		color: $gray-500;
	}
}

.convos__avatar_check.convos__avatar_check__hidden {
	display: none;
}

.convos__avatar_check.convos__avatar_check__checked {
	background: $primary;
	border-color: $primary;
	color: white;
}

.convos__title {
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex: 1;
	// 	font-size: 15px;
}

.convos__empty {
	background: #fff;
	position: relative;
	width: 100%;
	height: 280px;
	margin: 10px 0 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	justify-content: center;

	img {
		height: 160px;
	}
}

.convos__checked_header {
	display: flex;
	height: $convos-header-height;
	align-items: center;
	padding: $spacer $spacer * 2;
	width: 100%;
}

.convos__normal_header {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 $spacer 0 $spacer * 2;
}

.convos__filter {
	top: 2px;
	position: absolute !important;
	right: 0;
	transition: right 350ms;
}

.convos__filter.convos__filter__hidden {
	right: -200px;
}

.convos__search_filter_close_btn {
	cursor: pointer;
	color: #ccc;
	&:hover {
		color: $white;
	}
}

.convos_filter {
	position: fixed;
	z-index: $zindex-dropdown;
	background: $white;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	top: 75px;
	left: 300px;

	display: flex;
	width: 320px;
	height: 500px;
	flex-direction: column;
}

.convos_filter_header {
	padding: $spacer;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $border-color;
	flex-shrink: 0;
}

.convos_filter_wrapper {
	flex: 1;
	position: relative;
	overflow: hidden;
}

.convos_filter_inner {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: auto;
	padding: 10px 15px;
	transition: 0.3s;
}

.convos_preset_filter_link {
	cursor: pointer;
	//padding-right: 25px;
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 5px;

	&:last-child {
		margin-bottom: 0;
	}

	&.active {
		font-weight: 600;

		.convos_preset_filter_count {
			font-weight: 600;
		}
	}

	&.active:before {
		//content: '✓';
		color: $success;
		font-weight: 400;
		position: absolute;
		right: 5px;
		top: 0;
		font-size: 20px;
	}
}

.convos_preset_filter_link_img {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	object-fit: cover;
}

.convos__avatar.sample_convo_avatar {
	background-color: #ff9800;
	color: $white;
	border-radius: 50%;
	position: relative;
}

.convos_preset_filter_count {
	font-size: 13px;
	margin-left: auto;
	color: $gray-600;
}

.sample_convo_avatar_dot {
	padding: 2px 6px;
	background-color: $red;
	border: 2px solid $white;
	color: $white;
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-size: 11px;
	line-height: 1;
	bottom: 0;
	right: -5px;
	border-radius: 15px;
	font-weight: 500;
}
