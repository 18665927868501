.onboarding__title {
	text-transform: uppercase;
	border-bottom: 2px solid black;
	font-weight: 500;
	font-size: 11px;
	margin-bottom: 30px;
}

.onboarding_inner {
	width: 700px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	position: relative;
}

.onboarding__tagline2 {
	text-align: center;
	width: 400px;
	margin-bottom: $spacer * 5;
}

.onboarding__logo {
	margin-bottom: $spacer * 6;
}

.onboarding__box {
	flex: 1;
	border-radius: 5px;
	margin-top: $spacer * 3;
	border: 1px solid lightgray;
	margin: $spacer;
	padding: $spacer * 3;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.onboarding__tagline {
	text-transform: capitalize;
	text-align: center;
	color: $black;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: $spacer * 2;
	width: 400px;
}

.onboarding {
	align-items: center;
	justify-content: center;
	display: flex;
	background: #fff0e3;
	background: white;
	width: 900px;
	transition: left ease-out 450ms, opacity ease-out 250ms;
	opacity: 1;
	height: 100vh;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.onboarding.onboarding__hidden_right {
	opacity: 0;
	left: 100%;
}

.onboarding.onboarding__hidden_left {
	opacity: 0;
	left: -100%;
}

.onboarding-modal__head {
	font-size: 24px;
	padding: $spacer * 1.5 $spacer * 3;
	border-bottom: 1px solid $border-color;
	color: $gray-700;
}

.onboarding-modal__body {
}

.onboarding-modal__foot {
	padding: $spacer * 1.5 $spacer * 3;
	border-top: 1px solid $border-color;
}

.onboarding-box {
	padding: $spacer * 6 $spacer * 3 $spacer * 3;
	background-color: $gray-100;
	text-align: center;
	border-radius: 8px;
}

.onboarding-box__title {
	font-size: 20px;
	color: $gray-700;
	margin-bottom: $spacer * 0.25;
}

.onboarding-box__subtitle {
	color: $gray-600;
}

.onboarding-card {
	background-color: $white;
	border-radius: 8px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
	padding: $spacer * 3 $spacer * 2 $spacer * 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 260px;
	transition: 0.3s;
	min-height: 220px;
}

.onboarding-card__icon {
	color: $blue-400;
	margin-bottom: $spacer * 2;
	width: 32px;
	height: 32px;
}

.onboarding-card__title {
	font-size: 16px;
	margin-bottom: $spacer * 0.25;
}

.onboarding-card__subtitle {
	color: $gray-600;
	text-align: center;
}

.onboarding__box_logo {
	width: 30px;
	height: 30px;
	margin-bottom: $spacer * 2;
	img {
		width: 100%;
		height: 100%;
	}
}

.onboarding__box_button {
}

.onboarding__code {
	position: relative;
	width: 100%;
}

.onboarding__code_code {
	background: #eaeaea;
	color: #444;
	font-size: 13px;
	border-radius: 4px;

	width: 100%;
	white-space: pre-wrap;
	padding: $spacer $spacer * 2;

	overflow-y: auto;
	word-break: break-all;
	font-family: $font-family-monospace;
}

.onboarding__code_btn {
	position: absolute;
	bottom: 0px;
	right: -60px;
}
