@use 'sass:math';

// Variables
//
// Variables should follow the `$component-state-property-size` formula for

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$black-300: #e3e3e3 !default;
$black-500: #808080 !default;
$black-600: #666666 !default;
$black-700: #4c4c4c !default;

// fusv-disable
$grays: (
	'100': $gray-100,
	'200': $gray-200,
	'300': $gray-300,
	'400': $gray-400,
	'500': $gray-500,
	'600': $gray-600,
	'700': $gray-700,
	'800': $gray-800,
	'900': $gray-900,
) !default;
// fusv-enable

$blue: #0d6efd !default; // #0265ff
$red: #ea3d2f !default;
$orange: #f3aa18 !default;
$yellow: #ffc107 !default;
$green: #19b600 !default;

$colors: (
	'blue': $blue,
	'red': $red,
	'orange': $orange,
	'yellow': $yellow,
	'green': $green,
	'white': $white,
	'gray': $gray-600,
	'gray-dark': $gray-800,
) !default;

$primary: #0d6efd !default;
$primary-light: #e8f0ff !default;
$secondary: $gray-600 !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-800 !default;

$sidebar__color: #001757 !default;

$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'success': $success,
	'warning': $warning,
	'danger': $danger,
	'light': $light,
	'dark': $dark,
	'white': $white,
	'orange': $orange,
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 8) !default;
$blue-200: tint-color($blue, 6) !default;
$blue-300: tint-color($blue, 4) !default;
$blue-400: tint-color($blue, 2) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 2) !default;
$blue-700: shade-color($blue, 4) !default;
$blue-800: shade-color($blue, 6) !default;
$blue-900: shade-color($blue, 8) !default;

$red-100: tint-color($red, 8) !default;
$red-200: tint-color($red, 6) !default;
$red-300: tint-color($red, 4) !default;
$red-400: tint-color($red, 2) !default;
$red-500: $red !default;
$red-600: shade-color($red, 2) !default;
$red-700: shade-color($red, 4) !default;
$red-800: shade-color($red, 6) !default;
$red-900: shade-color($red, 8) !default;

$orange-100: tint-color($orange, 8) !default;
$orange-200: tint-color($orange, 6) !default;
$orange-300: tint-color($orange, 4) !default;
$orange-400: tint-color($orange, 2) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 2) !default;
$orange-700: shade-color($orange, 4) !default;
$orange-800: shade-color($orange, 6) !default;
$orange-900: shade-color($orange, 8) !default;

$yellow-100: tint-color($yellow, 8) !default;
$yellow-200: tint-color($yellow, 6) !default;
$yellow-300: tint-color($yellow, 4) !default;
$yellow-400: tint-color($yellow, 2) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 2) !default;
$yellow-700: shade-color($yellow, 4) !default;
$yellow-800: shade-color($yellow, 6) !default;
$yellow-900: shade-color($yellow, 8) !default;

$green-100: tint-color($green, 8) !default;
$green-200: tint-color($green, 6) !default;
$green-300: tint-color($green, 4) !default;
$green-400: tint-color($green, 2) !default;
$green-500: $green !default;
$green-600: shade-color($green, 2) !default;
$green-700: shade-color($green, 4) !default;
$green-800: shade-color($green, 6) !default;
$green-900: shade-color($green, 8) !default;

// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29')) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 10px !default;
$spacers: (
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer,
	4: $spacer * 2,
	5: $spacer * 3,
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
	(
		25: 25%,
		50: 50%,
		75: 75%,
		100: 100%,
		auto: auto,
	),
	$sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-900 !default;
$body-text-align: null !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-color: $gray-300 !default;

$border-radius: 0.25rem !default;
$border-radius-bg: 0.5rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-xs: 0.1rem !default;

$rounded-pill: 50rem !default;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075) !default;

$component-active-color: $white !default;
$component-active-bg: $primary !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:  'Inter Tight', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-monospace: Inconsolata, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base: 16px !default; // Assumes the browser default, typically `16px`
$font-size-bg: $font-size-base * 1.5 !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 500 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-lg: 2 !default;
$line-height-sm: 1.25 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: math.div($spacer, 2) !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 0.875em !default;

$sub-sup-font-size: 0.75em !default;

$text-muted: $gray-600 !default;

$initialism-font-size: $small-font-size !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-small-font-size: $small-font-size !default;
$blockquote-font-size: $font-size-base * 1.25 !default;

$hr-margin-y: $spacer !default;
$hr-color: inherit !default;
$hr-height: $border-width !default;
$hr-opacity: 0.25 !default;

$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.5rem !default;
$table-cell-padding-sm: 0.25rem !default;

$table-cell-vertical-align: top !default;

$table-color: $body-color !default;
$table-bg: null !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-color: $table-color !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;
$table-head-border-color: $gray-700 !default;

$table-striped-order: odd !default;

$table-caption-color: $text-muted !default;

$table-bg-level: -9 !default;
$table-border-level: -6 !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-xs: 0.2rem !default;
$input-btn-padding-x-xs: 0.3rem !default;
$input-btn-font-size-xs: $font-size-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;

$input-btn-padding-y-bg: 0.75rem !default;
$input-btn-padding-x-bg: 1.5rem !default;
$input-btn-font-size-bg: $font-size-bg !default;

$input-btn-border-width: $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-padding-y-bg: $input-btn-padding-y-bg !default;
$btn-padding-x-bg: $input-btn-padding-x-bg !default;
$btn-font-size-bg: $input-btn-font-size-bg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-bg: $border-radius-bg !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-xs: $border-radius-xs !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
	box-shadow 0.15s ease-in-out !default;

// Forms

$label-margin-bottom: 0.5rem !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;
$input-disabled-border-color: null !default;

$input-color: $gray-700 !default;
$input-border-color: $gray-400 !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: $box-shadow-inset !default;

$input-border-radius: $border-radius !default;
$input-border-radius-bg: $border-radius-bg !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: lighten($component-active-bg, 25%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-600 !default;
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y * 0.5) !default;

$input-height: 32px; // add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-check-input-width: 1rem !default;
$form-check-min-height: $font-size-base * $line-height-base !default;
$form-check-padding-left: $form-check-input-width + 0.25rem !default;
$form-check-margin-bottom: 0.125rem !default;
$form-check-label-cursor: pointer !default;

$form-check-input-active-filter: brightness(90%) !default;

$form-check-input-bg: $body-bg !default;
$form-check-input-border: 2px solid rgba(0, 0, 0, 0.25) !default;
$form-check-input-border-radius: 0.25em !default;
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: $input-focus-border-color !default;
$form-check-input-focus-box-shadow: none; //$input-btn-focus-box-shadow !default;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-repeat: no-repeat !default;
$form-check-input-checked-bg-position: center center !default;
$form-check-input-checked-bg-size: 1em !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;
$form-check-input-light-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$secondary}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/></svg>") !default;
$form-check-radio-light-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$secondary}'/></svg>") !default;

$form-check-input-bold-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$primary}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/></svg>") !default;
$form-check-radio-bold-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$primary}'/></svg>") !default;

$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-repeat: no-repeat !default;
$form-check-input-indeterminate-bg-position: center center !default;
$form-check-input-indeterminate-bg-size: 1em !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 8h6'/></svg>") !default;

$form-check-input-bold-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$primary}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 8h6'/></svg>") !default;

$form-text-margin-top: 0.25rem !default;

$form-check-inline-margin-right: 1rem !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;

$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-font-family: $input-font-family !default;
$form-select-font-size: $input-font-size !default;
$form-select-height: $input-height !default;
$form-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight: $input-font-weight !default;
$form-select-line-height: $input-line-height !default;
$form-select-color: $input-color !default;
$form-select-disabled-color: $gray-600 !default;
$form-select-bg: $input-bg !default;
$form-select-disabled-bg: $gray-200 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position: right $form-select-padding-x center !default;
$form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color: $gray-800 !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-right: add(
	1em * 0.75,
	(2 * $form-select-padding-y * 0.75) + $form-select-padding-x + $form-select-indicator-padding
) !default;
$form-select-feedback-icon-position: center right ($form-select-padding-x + $form-select-indicator-padding) !default;
$form-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width: $input-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $border-radius !default;
$form-select-box-shadow: $box-shadow-inset !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-width: $input-focus-width !default;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color !default;

$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-size-sm: $input-font-size-sm !default;
$form-select-height-sm: $input-height-sm !default;

$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-size-lg: $input-font-size-lg !default;
$form-select-height-lg: $input-height-lg !default;

// Form validation

$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $small-font-size !default;
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;

$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$form-validation-states: (
	'valid': (
		'color': $form-feedback-valid-color,
		'icon': $form-feedback-icon-valid,
	),
	'invalid': (
		'color': $form-feedback-invalid-color,
		'icon': $form-feedback-icon-invalid,
	),
) !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-widget: 100;
$zindex-dropdown: $zindex-widget + 10 !default;
$zindex-recent-users: $zindex-widget + 20 !default;
$zindex-sticky: $zindex-widget + 30 !default;
$zindex-fixed: $zindex-widget + 40 !default;
$zindex-modal-backdrop: $zindex-widget + 50 !default;
$zindex-modal: $zindex-widget + 60 !default;
$zindex-popover: $zindex-widget + 70 !default;
$zindex-tooltip: $zindex-widget + 80 !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: null !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;

// Toasts

$toast-max-width: 350px !default;
$toast-padding-x: 0.75rem !default;
$toast-padding-y: 0.25rem !default;
$toast-font-size: 0.875rem !default;
$toast-color: null !default;
$toast-background-color: rgba($white, 0.85) !default;
$toast-border-width: 1px !default;
$toast-border-color: rgba(0, 0, 0, 0.1) !default;
$toast-border-radius: $border-radius !default;
$toast-box-shadow: $box-shadow !default;

$toast-header-color: $gray-600 !default;
$toast-header-background-color: rgba($white, 0.85) !default;
$toast-header-border-color: rgba(0, 0, 0, 0.05) !default;

// Badges

$badge-font-size: 0.75em !default;
$badge-font-weight: $font-weight-normal !default;
$badge-color: $white !default;
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.5em !default;
$badge-border-radius: 5px !default;

// Code

$code-font-size: $font-size-base !default;
$code-color: #d63384 !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;

$pre-color: null !default;
