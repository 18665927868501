.user_label__delete {
	opacity: 0.6;
	&:hover {
		opacity: 1;
	}
}

.user_label__container {
	background: white;
	margin: 5px 5px 5px 0;
	border-radius: 4px;
	&:only-child {
		margin: 0 5px 0 0;
	}
}

.user_label {
	margin: 1px 5px 1px 0;
	display: inline-flex;
	align-items: center;
	padding: 2px 7px;
	border-radius: 10px;
	font-size: 13px;
	font-weight: 500;
	background: #f0f0f0;
	white-space: nowrap;
	color: #000;

	cursor: pointer;
	user-select: none;
}

.user_label__title {
	white-space: nowrap;
	line-height: 1.2;
	overflow: hidden;
	max-width: 150px;
	text-overflow: ellipsis;
}

.user_label.user_label__add_btn {
	border: 1px dashed $gray-600;
	color: $gray-800;
	&:hover {
		border: 1px dashed $black;
		color: $black;
	}
}

.user_labels {
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
}

.dropdown.convo_header__tag_dropdown__full {
	left: 0;
	.dropdown__item.dropdown__item__thin {
		padding: 2px 10px;
	}

	.dropdown__item:hover {
		color: white;
		background: #c4d0e6;
	}
}

.convo_header__tag_dropdown_item {
	padding: 0 $spacer;
	display: flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		background: #e8f0ff;
	}
}

.user_labels__more {
	position: relative;
	display: inline-block;

	.user_labels__dropdown {
		cursor: default;
		display: none;
		position: absolute;
		top: 80%;
		right: 0;
		background: $white;
		box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
		padding: $spacer;
		z-index: 10;
	}

	&:hover {
		.user_labels__dropdown {
			display: block;
		}
	}
}

.tag {
	display: inline-block;
	padding: 0 0.5rem;
	border-radius: 4px;
	line-height: 1.5;
	font-size: 14px;
	background: #ffffff;
	border: 1px solid transparent;
	white-space: nowrap;
	color: #555;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.tag--lg {
		padding: 2px 8px;
	}
}

.tag.tag--primary {
	color: #fff;
	background-color: $primary;
	border-color: $primary;
}

.tag.tag--warning {
	background: $warning;
	border-color: $warning;
}

.tag.tag--success {
	background: $success;
	border-color: $success;
	color: #333;
}

.tag.tag--danger {
	background: $danger;
	border-color: $danger;
	color: #fff;
}

.tag.tag--secondary {
	background: $secondary;
	border-color: $secondary;
	color: #fff;
}

.tag.tag--primary.tag--light {
	color: rgb(41, 116, 249);
	background: rgb(238, 244, 255);
	border-color: rgb(191, 213, 253);
}

.tag.tag--warning.tag--light {
	color: rgb(253, 174, 4);
	background: rgb(255, 249, 235);
	border-color: rgb(254, 231, 180);
}

.tag.tag--success.tag--light {
	color: rgb(3, 137, 47);
	background: rgb(235, 246, 238);
	border-color: rgb(179, 220, 193);
}

.tag.tag--danger.tag--light {
	color: rgb(252, 13, 6);
	background: rgb(255, 236, 235);
	border-color: rgb(254, 182, 180);
}

.tag.tag--secondary.tag--light {
	background: rgba(108, 117, 125, 0.1);
	border-color: rgba(108, 117, 125, 0.7);
	color: $secondary;
}
