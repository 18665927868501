.internal_chat {
	width: 325px;
	height: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	background: #f6f8ff; // #f0f0f0;
	border-left: 1px solid #e8e8e8;
	// transition: width 150ms ease;
}

.internal_chat.internal_chat__hidden {
	width: 0;
}

.message_internal_actions_dropdown {
	left: 100px;
	min-width: 150px;
	width: 150px;
}

.message_internal_actions {
	z-index: 20;
	transition: opacity 150ms ease, visibility 150ms ease;
	visibility: hidden;
	opacity: 0;
	display: flex;
	padding: 2px;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
}

.message_internal_action {
	cursor: pointer;
	color: $gray-800;
	&:hover {
		color: $primary;
	}
	margin-left: 5px;
	margin-right: 5px;
}

.internal_chat {
	.message_container {
		position: relative;
		display: flex;
		// dont know why flex column
		//flex-direction: column;

		flex-direction: row;
		align-items: center;
		width: unset;
		padding-right: 10px;
		&:hover {
			.message_actions {
				display: flex;
			}

			.message_internal_actions {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.convo_event__time {
		display: block;
		text-align: center;
		margin-top: $spacer * 2;
		color: $gray-600;
	}

	.message_file {
		border-radius: 7px;
		padding: $spacer;
		margin-top: $spacer * 0.5;
		border-radius: 10px;
		cursor: pointer;
		max-width: 80%;
		overflow: hidden;
	}

	.message_file.message_file__agent {
		margin-right: 0;
		align-self: flex-start;

		margin-left: 15px;
		color: $black;
		background-color: $white;
	}
	// LINK

	.message_seen {
		display: inline-block;
		margin-right: $spacer * 5;
		align-self: flex-end;
		color: $text-muted;
		margin-top: $spacer * 0.5;
	}

	.message_link__img {
		margin-top: 5px;
		border-top-right-radius: 7px;
		border-bottom-right-radius: 7px;
		max-height: 450px;
		max-width: 100%;
		object-fit: contain;
		display: block;
		border-left: 3px solid $gray-400;
		padding-left: $spacer;
	}

	.message_link__description {
		background: $white;
		border: 1px solid #eeeeee;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 4px 36px rgba(0, 0, 0, 0.05);
		padding: $spacer;
		white-space: pre-wrap;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		word-break: break-word;
	}

	.message_link {
		margin-top: $spacer * 0.5;
		position: relative;
	}

	// IMAGE
	.message_image.message_image__agent,
	.message_image.message_image__dummy {
		display: inline-block;
		margin-right: 0;
		margin-left: 15px;
		align-self: flex-start;
	}

	.message_image__img {
		max-height: 450px;
		max-width: 100%;
		object-fit: contain;
		border-radius: 7px;
		min-width: 80px;
	}

	.message_image {
		margin-top: $spacer * 0.5;
		position: relative;
	}

	.message_video.message_video__agent,
	.message_video.message_video__dummy {
		margin-left: 15px;
		display: inline-block;
		margin-right: 0;
		align-self: flex-start;
	}

	.message_video {
		margin-top: $spacer * 0.5;
		position: relative;
	}

	.message_video__video {
		border-radius: 10px;
		width: 100%;
		height: 100%;
		&:focus {
			outline: none;
		}
	}

	.message_markdown.message_markdown__user {
		display: inline-block;
		margin-left: $spacer * 5;

		color: $black;
		background: $white;
		border: 1px solid #eeeeee;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 4px 36px rgba(0, 0, 0, 0.05);
	}

	.message_markdown.message_markdown__agent,
	.message_markdown.message_markdown__dummy {
		display: inline-block;
		margin-right: $spacer * 5;
		color: $agent-message-text-color;
		background: $agent-message-background-color;
		align-self: flex-end;
		a {
			color: $white;
		}
	}

	.message_markdown {
		border-radius: 7px;
		word-break: break-word;
		position: relative;
		padding: $spacer;
		margin-top: $spacer * 0.5;
		max-width: 100%;
		overflow: auto;
	}

	.message_container.message_container__error {
		.message_text {
			opacity: 0.5;
		}
	}

	.message_text.message_text__deleted {
		text-decoration: line-through;
	}

	.message_text {
		white-space: pre-wrap;
		border-radius: 7px;
		word-break: break-word;
		margin-left: 15px;
		position: relative;
		padding: 10px 15px;
		margin-top: $spacer * 0.5;
	}

	.message_text.message_text__agent,
	.message_text.message_text__dummy {
		align-self: flex-start;
		margin-right: 0;
		color: $black;
		background: #dae2ff;
		a {
			color: $primary;
		}
	}

	.pinned_desc {
		margin-top: 5px;
		margin-left: 20px;
		color: #666;
		font-size: 13px;
	}
	.message_text.message_text__agent.message_text__pinned {
		background: #ffedb7;
	}

	.message-text--error {
		color: rgb(72, 66, 66);
	}

	.message_audio.message_audio__user {
		display: inline-block;
		margin-left: $spacer * 5;
	}

	.message_audio.message_audio__agent,
	.message_audio.message_audio__dummy {
		display: inline-block;
		margin-right: $spacer * 5;
		align-self: flex-end;
	}

	.message_audio {
		margin-top: $spacer * 0.5;
		position: relative;

		width: 100%;
		margin: 5px 0 0 0;
		height: 45px;
		display: inline;
	}

	.message_audio__audio {
		all: inherit;
		width: 100%;
	}

	.message__info {
		text-align: center;
		color: $gray-600;
		margin: 10px;
	}

	.message_state {


		position: absolute;
		z-index: 1;
		bottom: 0px;
		display: none !important; // flex
		align-items: center;
		right: unset;
		left: $spacer * 3;
		display: block;
	}

	.message_state.message_state__received {
		width: 12px;
		height: 12px;
		color: $white;

		padding: 2px;
		border-radius: 50%;
		background: $gray-500;
	}

	.message_state.message_state__error {
		right: $spacer * 3 - 1px;
		width: 14px;
		fill: $yellow; //$danger;
		// stroke: #900000;
		stroke: $danger;
	}

	.message_state.message_state__seen {
		width: 13px;
		color: $success;
	}

	.message_state.message_state__sending {
		width: 13px;
		color: $gray-400;
	}

	.message_state.message_state__sent {
		display: none;
		width: 12px;
		height: 12px;
		color: $white;

		padding: 2px;
		border-radius: 50%;
		background: $gray-300;
	}

	.message_actions.message_actions__show {
		display: flex;
	}

	.message_container.message_container__agent,
	.message_container.message_container__dummy {
		align-self: flex-start;
	}

	.message_container.message_container__user {
		display: flex;
		flex-direction: row;
	}

	.text_message_sender.text_message_sender__agent,
	.text_message_sender.text_message_sender__dummy {
		justify-content: flex-start;
		flex-direction: row;
	}

	.text_message_sender {
		display: flex;
		align-items: flex-end;
		margin-top: $spacer * 2;
		margin-bottom: math.div($spacer, 2);
	}

	.text_message_sender__avatar {
		width: 26px;
		margin-left: 15px;
		margin-right: 10px;
		display: flex;
		justify-content: center;
	}

	.text_message_sender__name {
		display: flex;
		font-weight: 500;
		align-items: center;
		color: $gray-700;
	}

	.message-system {
		text-align: center;
		margin: 10px;
		color: $gray-600;
	}

	.message_form.message_form__agent,
	.message_form.message_form__dummy {
		display: inline-block;
		margin-right: $spacer * 5;
		align-self: flex-end;
	}

	.message_editor.message_editor__plain {
		background: white;
	}

	.message_reactions {
		position: relative;
		display: flex;
		background: #fff;
		box-shadow: none;
		border-radius: 35px;
		z-index: 1;
		bottom: 3px;
		padding: 1px 4px;
		align-items: center;
	}

	.message_reactions.message_reactions__agent {
		margin-left: $spacer * 2;
	}

	.message_reactions__reaction {
		display: flex;
		align-items: center;
		position: relative;
		margin-right: $spacer;
		font-weight: 500;
		&:last-child {
			margin-right: 0;
		}
		cursor: pointer;
	}

	.message_reactions__reaction_counter.message_reactions__reaction_counter__active {
		color: $primary;
	}

	.message_reactions__reaction_counter {
		color: $black;
		margin-left: $spacer * 0.3;
		font-size: $font-size-sm;
	}
}

.internal_chat__subheader {
	align-items: center;
	padding: 0 15px;
	border-bottom: 1px solid $section-border-color;
	height: 36px;
	display: flex;
	background: white;
}

.internal_chat__header {
	flex-shrink: 0;
	align-items: center;
	background: white;
	display: flex;
	height: 56px;
	border-bottom: 1px solid $section-border-color;
}

.internal_chat__chat {
	position: absolute;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex: 1;
	left: 0;
	width: 100%;
	top: 0;
	bottom: 0;
	// transition: left 150ms ease, visibility 150ms ease;
	z-index: 3;
	background: #f6f8ff;
	visibility: visible;
}

.internal_chat__chat.internal_chat__chat__hidden {
	visibility: hidden;
	left: 300px;
}

.internal_chat__add_group {
	position: absolute;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex: 1;
	left: 0;
	width: 100%;
	top: 0;
	bottom: 0;
	transition: opacity 150ms ease;
	z-index: 3;
	border-left: 1px solid #e8e8e8;
	background: #f0f0f0;
	visibility: visible;
	background: #88888888;
	align-items: center;
	justify-content: center;
}

.internal_chat__add_group__body {
	width: 90%;
	background: white;
}

.internal_chat__add_group.internal_chat__add_group__hidden {
	opacity: 0;
	visibility: hidden;
}

.internal_chat__label {
	font-weight: 500;
	margin-top: 20px;
	margin-bottom: 10px;
}

.internal_chat__search_input {
	display: block;
	width: 100%;
	border: 0px;
	// background: red;
	padding: 3px 1px 3px 35px;

	&:active,
	&:focus {
		outline: none;
	}
}

.message_editor_quote {
	width: 100%;
	border-bottom: 1px solid $gray-500;
	display: flex;
	padding: 5px 10px;

	&.message_editor_quote_hide {
		display: none;
	}
}

.message_editor_quote_content {
	font-size: 13px;
	padding-right: 10px;
	overflow: hidden;
}

.message_editor_quote_text {
	color: $gray-600;
	display: -webkit-box;
	// overflow in 3 line
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	line-height: 1.25;
	white-space: pre;
}

.internal_chat_avatar_edit_icon {
	position: absolute;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.35);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	color: $white;
	border-radius: 4px;
	line-height: 1;
}

.internal_chat_avatar_edit_wrapper {
	position: relative;
	cursor: pointer;
}
