.calendar {
	width: 100%;
	max-width: 360px;
	width: 250px;
	border-radius: 4px;
	overflow: hidden;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	/*
		padding: 5px;
	 border: 1px solid rgba(101, 109, 119, 0.16);
*/

	.calendar--nav {
		padding: 0px 5px 0px 5px;
		display: flex;
		align-items: center;
		color: black;
		font-weight: 500;
		justify-content: space-between;
		position: relative;
	}

	.calendar--nav a {
		cursor: pointer;
		line-height: 1;
		top: 16px;
		width: 30px;
		text-align: center;
		display: inline-block;
		color: rgba(255, 255, 255, 0.4);
		user-select: none;
	}

	.calendar--days {
		font-size: 0;
		white-space: initial;
	}

	.calendar--days span {
		width: 14.28571%;
		display: inline-block;
		text-align: center;
		user-select: none;
		cursor: pointer;
		margin: 1px 0;
		line-height: 30px;
		position: relative;
		font-size: 14px;
	}
	.calendar--days span.label {
		text-transform: uppercase;
		font-weight: 700;
		color: rgba(0, 0, 0, 0.3);
		font-size: 14px;
		cursor: initial;
	}
	.calendar--days span.active {
		font-weight: 700;
		background-color: #b670f436;
		border-radius: 12px;
	}

	.calendar--days span.selected {
		font-weight: 700;
		background-color: #0d6dfa;
		color: white;
		border-radius: 12px;
	}

	.calendar--days span.muted {
		color: rgba(0, 0, 0, 0.3);
	}
	.calendar--days span.between {
		border-radius: 0;
	}

	.calendar--days span.start,
	.calendar--days span.between,
	.calendar--days span.end {
		background-color: var(--tblr-primary); /* #2f9792
	background-color: #2f9792;*/
		color: #fff;
	}
	.calendar--days span.start.end {
		border-radius: 20px;
	}

	.calendar--days span.start {
		border-radius: 20px 0 0 20px;
	}
	.calendar--days span.end {
		border-radius: 0 20px 20px 0;
	}

	.calendar--days span.between:nth-child(7n):after,
	.calendar--days span.start:nth-child(7n):after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 100%;
		background-color: $primary; /* #2f9792 */
		width: 20px;
	}
	.calendar--days span.between:nth-child(7n + 1):after,
	.calendar--days span.end:nth-child(7n + 1):after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 100%;
		background-color: $primary;
		width: 20px;
	}
	.calendar--days span.start.end:after {
		display: none;
	}

	.calender-thin .calendar--days span {
		line-height: 20px;
	}

	.calender-thin .calendar--nav {
		padding-top: 0;
	}

	.calendar--days span.start,
	.calendar--days span.between,
	.calendar--days span.end {
		background-color: $primary;
		color: #fff;
	}

	.calendar--day {
		&:hover {
			border-radius: 12px;
			background: #2196f33d;
		}
	}
}

.calendar_shortcut {
	width: 100px;
	padding: 20px 0;
	background: #f7f7f7;
	border-right: 1px solid #e4e4e4;
}

.calendar_wrapper__top .calendar_container {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.calendar_container {
	position: absolute;
	z-index: 101;
	background: white;
	display: flex;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: hidden;
}

.calendar_shortcut__item {
	cursor: pointer;
	@include text-truncate;
	&:hover {
		background: $primary;
		color: white;
	}
	padding: 3px 10px;
}
