.tab {
	display: flex;
	border-bottom: 1px solid #dadce0;
	margin-bottom: $spacer;
}

.tab.tab__right {
	justify-content: flex-end;
}

.tab.tab__light {
	.tab__item {
		font-weight: normal;
	}
}

.tab__item {
	position: relative;
	margin-bottom: -1px;
	padding: $spacer 0 $spacer 0;
	cursor: pointer;
	user-select: none;
	margin-right: $spacer * 2;
	color: $gray-600;
	font-weight: 500;
	&:hover {
		color: $gray-800;
	}

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: 0;
		width: 50%;
		border-bottom: 1px solid #dadce0;
		transition: all ease 250ms;
	}
	&:last-child {
		margin-right: 0;
	}
}

.tab__item.tab__item__secondary {
	font-weight: 400;
}

.tab__item.tab__item__secondary.tab__item__active {
	&:after {
		border-bottom-color: $secondary;
	}
}

.tab__item.tab__item__active {
	color: $black;
	&:after {
		width: 100%;
		border-bottom: 2px solid $primary;
		transition: width ease 150ms;
	}
}
