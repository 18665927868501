$header-bg: #07224b;
$header-height: 45px;

.root {
	display: flex;
	flex: 1;
	//background: $header-bg;
	flex-direction: column;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
}

.root__header {
	flex-shrink: 0;
	height: $header-height; // see header_bar
}

.root__body {
	display: flex;
	flex: 1;
	flex-direction: row;
	overflow: hidden;
}

.root_content {
	background: white;
	position: relative;
	overflow: hidden;
	flex: 1;
}

.sidebar {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 45px;
	position: relative;
	height: 100%;
	background: $white;
	z-index: 100;
	border-right: 1px solid $border-color;
}

.sidebar__item_label {
	color: #444;
	@include text-truncate();
	margin-left: 10px;
}

.sidebar__sub_item_icon {
	width: 100%;
	cursor: pointer;
	display: flex;
	position: relative;
	align-items: center;

	padding: $spacer $spacer * 2;
	color: $gray-400;
	stroke: $gray-400;
	&:hover {
		color: $white;
		stroke: $white;
	}
}

.sidebar__sub_item_icon.sidebar__sub_item_icon__active {
	color: $gray-700;
	stroke: $gray-700;
}

.sidebar__sub_item_icon.sidebar__sub_item_icon__profile {
	padding-bottom: 0;
}

.sidebar__sub_item {
}

.popup_notification {
	position: absolute;
	z-index: 9999;
	right: 50%;
	left: 50%;
}

.popup_notification__item {
	display: flex;
	justify-content: center;
	border-radius: 5px;
	max-width: 80vw;
	margin: 10px auto;
	padding: 7px 20px;
	color: #fff;
}

.hellobar {
	padding: 6px 0 7px 0;
}

.modal__container.notify-popup {
	position: fixed;
	top: 10px;
	left: 20px;
	z-index: 150;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
}

.notify-popup__head {
	width: 497px;
	height: 300px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.notify-popupw .modal-main {
	display: grid;
	grid-template-rows: 35% 65%;
	background: rgba(67, 129, 248, 0.1);
}

.notify-popup .body {
	padding: 0 30px;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	color: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #fff;
}

.notify-popup__title {
	font-size: 22px;
	font-weight: 700;
	text-transform: capitalize;
}
.notify-popup .modal-content {
	background: #fff;
	width: 497px;
	border-radius: 8px;
}

.notify-popup__desc {
	font-size: 18px;
	opacity: 0.8;
}

.permission-denied .recieive-notify {
	color: #fff;
	margin-left: 15px;
	padding: 5px 20px;
	background: #000;
	border-radius: 8px;
	cursor: pointer;
}

.nav_my_profile {
	border-radius: 8px;
	top: calc(100% + 11px);
	right: 0;
	position: absolute;
	width: 250px;
	background-color: #fff;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	z-index: 2147483647;
	&:after {
		border: solid transparent;
		content: ' ';
		position: absolute;
		pointer-events: none;
		border-color: hsla(0, 0%, 100%, 0);
		border-bottom-color: $gray-200;
		border-width: 10px;
		top: -18px;
		right: 5px;
	}
}

.nav_my_profile__info {
	padding: $spacer * 2;
	background-color: $gray-200;
	display: flex;
}

.help_wave.help_wave__hidden {
	opacity: 0;
}

.help_wave {
	cursor: pointer;
	position: absolute;
	right: 40px;
	width: 250px;
	bottom: 157px;
	background: $primary;
	user-select: none;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	padding: 10px;
	border-radius: 8px;
	color: white;
	transition: all 0.2s;
	z-index: 2147483647;
	// transform: rotate(-90deg);
	&.has_phone {
		right: 44px;
	}
}

.error_settings_dropdown {
	position: absolute;
	right: 7px;
	width: 335px;
	top: 6px;
	user-select: none;
	background-color: #ea3d2f;
	color: white;
	box-shadow: 0 2px 20px rgb(0 0 0 / 44%);
	padding: 10px;
	border-radius: 8px;
	transition: all 0.2s;
	z-index: 905;
}

.activity__root {
	display: flex;
	overflow: hidden;
	height: 100%;
}

.main {
	display: flex;
	overflow: auto;
	position: relative;
	display: flex;
	height: 100%;
	justify-content: space-between;
}

.quickview {
	width: 100%;
	position: relative;
	height: 100%;
	display: flex;
}

.quickview__btn_close {
	position: absolute;
	z-index: 10;
	flex-shrink: 0;
	margin-right: $spacer;
	margin-top: $spacer;
	top: 10px;
	right: 10px;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;

	cursor: pointer;
	.quickview__btn_close__icon {
		stroke: $gray-700;
	}
	&:hover {
		.quickview__btn_close__icon {
			stroke: $black;
		}
	}
}

.header_bar.header_bar__churned {
	background-color: #848484;
}

.header_bar {
	background-color: $header-bg;

	height: $header-height; // see .root__header
	padding: 0 $spacer * 2;
	display: flex;
}

.header_bar_left {
	display: flex;
	flex-wrap: none;
}

.header_bar_center {
	flex: 1;
	display: flex;
	flex-wrap: nowrap;
}

.header_bar_logo {
	margin-right: $spacer * 2;
	width: 80px;
	object-fit: cover;
	align-self: center;
	cursor: pointer;
	margin-top: -4px;
}

.header_bar_menu_link {
	font-weight: 500;
	font-size: 16px;
	flex-shrink: 0;
	color: #ffffffbb;
	padding: 8px 10px;
	// margin: 0 $spacer;

	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

	&:hover {
		color: white;
	}

	&.active {
		background: rgb(0 0 0 / 46%);
		color: white;
		//margin-top: 2px;
		// border-bottom: 2px solid $primary;
		&:after {
			width: 100%;
			transition: width ease 150ms;
		}

		.sidebar__icon_active_subiz_live {
			border: 3px solid #09183c;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: -1px;
		width: 50%;
		transition: all ease 250ms;
	}

	// for dropdwon
	.dropdown_item {
		color: initial;
	}
}

.header_bar_menu_link_icon {
	display: block;
	stroke-width: 1.5;
	outline: none;
}

.header_bar_message_count_badge {
	padding: 0 5px;
	background-color: #ea3d2f;
	color: #fff;
	font-size: 10px;
	top: 24px;
	position: absolute;
	font-weight: 500;
	margin-left: 5px;
	left: 8px;
	border-radius: 15px;
}

.header_bar_task_count_badge {
	padding: 0 5px;
	background-color: #838383;
	color: #fff;
	font-size: 10px;
	top: 24px;
	position: absolute;
	font-weight: 500;
	margin-left: 5px;
	left: 8px;
	border-radius: 15px;
}

.header_bar_noti_count_badge {
	background-color: $danger;
	color: $white;
	padding: 3px 5px 2px 5px;
	border-radius: 15px;
	display: flex;
	font-size: 9px;
	font-weight: 700;
	border: 2px solid $header-bg;
	position: absolute;
	top: 9px;
	line-height: 1;
	right: -7px;
	align-items: center;
}

.header_bar_call_dot {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 3px solid $header-bg;
	position: absolute;
	bottom: 12px;
	right: 2px;
	align-items: center;
}

.status_agent_dot {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid #ffffff;
	align-items: center;
	position: absolute;
	bottom: 3px;
	left: 28px;
}

.item_unavailable {
	opacity: 0.5;
}

.header_bar_right {
	display: flex;
	margin-left: auto;
}

.header_bar_agent_avatar_container {
	align-self: center;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	margin-left: $spacer;
	position: relative;
}

.header_bar_agent_avatar {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
	border-radius: 50%;
}

@keyframes status-pulsate-tertiary {
	25% {
		transform: scale(1, 1);
	}

	80% {
		transform: scale(3, 3);
		opacity: 0;
	}

	100% {
		transform: scale(3, 3);
		opacity: 0;
	}
}

.sidebar__icon_active_subiz_live {
	position: absolute;
	background: #19b600;
	border-radius: 50%;
	border: 3px solid $header-bg;
	left: 23px;
	width: 14px;
	height: 14px;
	top: 21px;
}

.sidebar__icon_active_subiz_live.play {
	&::before {
		content: '';
		position: absolute;
		inset: 0;
		z-index: 0;
		background: inherit;
		border-radius: inherit;
		opacity: 1;

		animation-duration: 2s;
		animation-name: status-pulsate-tertiary;
	}
}

.more_menu_dropdown {
	border-radius: 8px;
	top: calc(100% - 2px);
	padding: $spacer;
	right: -25px;
	position: absolute;
	width: 350px;
	background-color: #fff;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	z-index: $zindex-dropdown;
	&:after {
		border: solid transparent;
		//content: ' ';
		position: absolute;
		pointer-events: none;
		border-color: hsla(0, 0%, 100%, 0);
		border-bottom-color: $white;
		border-width: 10px;
		top: -18px;
		right: 8px;
	}
}

.more_menu_items_container {
}

.more_menu_item {
	background-color: transparent;
	border-radius: 8px;
	cursor: pointer;
	color: $gray-700;
	padding: $spacer;
	display: flex;

	&:hover {
		background-color: $gray-100;
	}
}

.more_menu_item_icon {
	width: 30px;
	color: $gray-600;
	margin-right: $spacer;
}

.more_menu_item_header {
	font-weight: 500;
	color: $gray-600;
}

.more_menu_item_sub {
	color: $gray-600;
	font-size: 15px;
	font-weight: 400;
}

.root_content_inner {
	width: 100%;
	height: 100%;
	overflow: hidden hidden;
	position: relative;
}

.call_online_dot {
	background: #19b600;
}

.call_offline_dot {
	background: #ffbb28;
}

.call_unavailable_dot {
	background: rgb(160, 160, 160);
}

.call_item {
	display: flex;
}

.call_icon {
	color: #ffffff;
}

.calling_number_modal__key {
	width: 60px;
	font-family: $font-family-monospace;
	height: 60px;
	background: #282d4e;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-bottom: 10px;
	cursor: pointer;
	font-size: 22px;
	font-weight: 500;
	margin-left: 15px;
	&:hover {
		background: #16192c;
	}
}

.calling_number_modal__row {
	padding-right: 15px;
	display: flex;
	align-items: center;
}

.calling_number_modal__input {
	font-family: $font-family-monospace;
	border: none;
	background: transparent;
	outline: none;
	width: 210px;
	color: white;
	font-size: 24px;
}

.calling_number_modal {
	color: white;
	position: fixed;
	z-index: $zindex-dropdown + 11;
	overflow-x: hidden;
	top: 90px;
	right: 80px;

	background: #33395e;
	// border: 1px solid #d1d1d1;
	border-radius: 14px;
	padding: 15px 5px;
	box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 10px 40px 0px,
		rgb(0 0 0 / 8%) 0px 0px 16px 0px;
}

.calling_transfer_modal {
	position: fixed;
	z-index: $zindex-dropdown + 11;
	overflow-x: hidden;
	top: 90px;
	right: 100px;
	width: 220px;
	background: #33395e;
	border-radius: 14px;
	padding: 15px 5px;
	box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 10px 40px 0px,
		rgb(0 0 0 / 8%) 0px 0px 16px 0px;
}

.calling_modal_box_warning {
	background-color: $warning;
	padding: 2px 10px;
	text-align: center;
}

.calling_modal_warning {
	color: $black;
	font-size: 14px;
	font-weight: 500;

	&:hover {
		color: #403737;
	}
}

.calling_modal {
	color: white;
	z-index: $zindex-modal;
	position: fixed;
	z-index: $zindex-dropdown;
	overflow-x: hidden;
	top: 60px;
	right: 67px;
	width: 360px;

	background: #33395e;
	// border: 1px solid #d1d1d1;
	border-radius: 14px;
	box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 10px 40px 0px,
		rgb(0 0 0 / 8%) 0px 0px 16px 0px;
}

.calling {
	color: white;
	z-index: $zindex-modal;
	position: fixed;
	overflow-x: hidden;
	bottom: 74px;
	right: 35px;
	width: 330px;
	align-items: flex-end;
	// border: 1px solid #d1d1d1;
	border-radius: 14px;

	box-shadow: 0px 0px 0px 0px, rgb(0 0 0 / 22%) 0px 10px 40px 0px, rgb(0 0 0 / 17%) 0px 0px 16px 0px;
}

.btn_transfer {
	width: 125px;
	height: 34px;
	background: rgba(0, 0, 0, 0.12);
	border-radius: 6px;
	border: none;

	&:hover {
		background: rgba(0, 0, 0, 0.22);
	}
}

.btn_call_later {
	background: #3b3b3b;
	color: #ffffff;

	&:hover {
		background: #3b3b3b69;
		color: rgba(255, 255, 255, 0.788);
	}
}

.calling_modal_minimize {
	height: 40px;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	cursor: pointer;
}

.calling_modal_content {
	display: flex;
	align-items: center;
	height: 75px;
	padding: 12px 15px;
}

.calling_modal_header {
	padding: 0 15px;
	height: 45px;
	display: flex;
	align-items: center;
	background: #282d4e;
}

.call_icon_option {
	margin-left: 10px;
	border: none;
	border-radius: 6px;
}

.minimize_icon {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
	background-color: rgba(0, 0, 0, 0.17);
	font-weight: 500;
	transifion: background-color 150s ease-out;
	&:hover {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.calling_user_name {
	font-weight: 500;
	font-size: 20px;
	@include text-truncate();
	color: white;
}

.calling_user_number {
	font-weight: 400;
	font-size: 18px;
	color: white;
	@include text-truncate();
}

.webphone_disconnected {
	z-index: 1000;
	border-radius: 50%;
	top: -11px;
	right: -33px;
	position: absolute;
	background-color: $warning;
	border: 2px solid white;
	transform: rotate(-90deg);
	width: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 18px;

	font-size: 13px;
	line-height: 1;
	font-weight: 700;
}

.global_help_button {
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	transform: rotate(-90deg) translate(-100%, -50%);
	width: 120px;
	height: 50px;
	display: flex;
	padding-top: 5px;
	align-items: flex-start;
	font-size: 13px;
	background-color: $primary;

	color: $white;
	border-radius: 0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	z-index: 999;
	font-weight: 500;
	position: absolute;
	bottom: 260px;
	right: -80px;
	display: flex;
	justify-content: center;
	transition: 0.25s;
}

.global_help_button.global_help_button__enabled {
	cursor: pointer;
	&:hover {
		transform: rotate(-90deg) translate(-100%, -70%);
	}
}

.global_call_button {
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	transform: rotate(-90deg) translate(-100%, -50%);
	width: 70px;
	height: 50px;
	display: flex;
	padding-top: 5px;
	align-items: flex-start;
	font-size: 13px;
	background-color: $success;

	color: $white;
	border-radius: 0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	z-index: 999;
	font-weight: 500;
	position: absolute;
	bottom: 100px;
	right: -55px;
	display: flex;
	justify-content: center;
	transition: 0.25s;

	cursor: pointer;
	&:hover {
		transform: rotate(-90deg) translate(-100%, -70%);
	}

	.icon-tabler-phone {
		stroke: transparent;
	}
	.icon-tabler-phone path:last-child {
		fill: currentColor;
	}

	&.global_call_button__muted {
		background-color: $dark;
	}
}

.global_call_button.global_call_button__disabled {
	cursor: default;
	background-color: $gray-400;
}

.global_call_button.global_call_button__warning {
	background-color: $warning;
}

.link.link__dark {
	&:hover {
		color: white;
		stroke: $white;
	}
	color: white;
	stroke: $white;
}

.modal_content.onboarding_modal_content {
	width: 500px;
	position: relative;
}

.onboarding_modal_content_inner {
	padding: 15px 20px;
	padding-bottom: 0;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.onboarding_modal_x {
	width: 28px;
	height: 28px;
	background-color: $white;
	color: $gray-500;
	cursor: pointer;
	border-radius: 50%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 20px;
	right: 20px;
	border: 1px solid $border-color;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
	z-index: 1;

	&:hover {
		color: $gray-600;
	}
}

.onboarding_modal_nav_dot {
	width: 16px;
	height: 16px;
	cursor: pointer;
	position: relative;

	&.active {
		cursor: auto;

		&:before {
			background-color: $primary;
		}
	}

	&:before {
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 9999px;
		background-color: $gray-400;
		top: 4px;
		left: 4px;
	}
}

.onboarding_modal_img_wrapper {
	height: 370px;
	overflow: hidden;
	position: relative;
}

.onboarding_modal_content_wrapper {
	position: relative;
	height: 120px;
	overflow: hidden;
}

.onboarding_modal_content_container {
	position: absolute;
	top: 0;
	left: 0;
	transition: 0.5s;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.onboarding_modal_img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.5s;
	z-index: -1;
}

.onboarding_modal_img_navigation {
	cursor: pointer;
	position: absolute;
	z-index: 1;
	color: rgba(255, 255, 255, 0.6);
	top: 50%;
	transform: translateY(-50%);

	&:hover {
		color: rgba(255, 255, 255, 0.85);
	}

	&.left {
		left: 0;
	}

	&.right {
		right: 0;
	}
}

.onboarding_modal_title {
	font-size: 24px;
	font-weight: 500;
}

.onboarding_modal_desc {
	margin-top: 0;
	@extend .text__muted;
}

.onboarding_modal_img_dots {
	width: 100%;
	bottom: 10px;
	display: flex;
	justify-content: center;
	position: absolute;
	left: 0;
	z-index: 1;
}

.global_email_button {
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	transform: rotate(-90deg) translate(-100%, -50%);
	width: 70px;
	height: 50px;
	display: flex;
	padding-top: 5px;
	align-items: flex-start;
	font-size: 13px;
	background-color: #d44638;

	color: $white;
	border-radius: 0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	z-index: 999;
	font-weight: 500;
	position: absolute;
	bottom: 320px;
	right: -55px;
	display: flex;
	justify-content: center;
	transition: 0.25s;

	cursor: pointer;
	&:hover {
		transform: rotate(-90deg) translate(-100%, -70%);
	}
}

.global_email_window {
	width: 600px;
	height: 600px;
	position: fixed;
	background-color: $white;
	bottom: 0;
	z-index: 170; // z-index bigger than quickview z-index
	right: 60px;
	overflow: hidden;
	box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12),
		0px 5px 5px -3px rgba(0, 0, 0, 0.2);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	&.hidden {
		display: none;
	}

	&.mini {
		width: 300px;
		height: unset;

		.global_email_window_content {
			display: none;
		}

		.global_email_window_footer {
			display: none;
		}
	}
}

.global_email_window_header {
	padding: 4px 10px;
	background-color: $gray-100;
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.global_email_window_content {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.lexical_editor_tabs_wrapper {
		flex: 1;
		overflow: hidden;
		border-top: 1px solid $border-color;
	}

	.lexical_editor_wrapper {
		border: none;
		outline: none;
		height: 100%;
	}
}

.global_email_window_content_info_wrapper {
	display: flex;
	align-items: center;
	padding: 4px 0px;
	margin-left: 10px;
	margin-right: 10px;
	border-bottom: 1px solid $border-color;

	&:last-child {
		border-bottom: none;
	}
}

.global_email_window_footer {
	padding: 4px 10px;
	background-color: $gray-100;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-shrink: 0;
}

.warning_modal_wrapper {
	width: 420px;
	position: relative;

	.warning_modal_header {
		background-color: #9d9d9d;
		height: 200px;

		justify-content: center;
		align-items: center;
	}

	.warning_modal_content_wrapper {
		padding: 10px 30px;

		.warning_modal_content_title {
			font-size: 20px;
			font-weight: 700;
			margin: 10px;
		}
	}
}

.bg-success {
	background-color: $success;
}

.bg-secondary {
	background-color: $secondary;
}

.bg-warning {
	background-color: $warning;
}

.bg-danger {
	background-color: $danger;
}

.bg-dark {
	background-color: $dark;
}

.hello-bar.bg-warning {
	background-color: #fe9a28; // more gold
}

.hello-bar {
	a {
		color: white;
		font-weight: 700;
		text-decoration: underline;
	}

	b {
		font-weight: 700;
	}
	color: white;
	height: 24px;
	font-size: 12px;
	line-height: 1;
	padding: 6px 0;
	font-weight: 500;
	position: relative;
}
