@mixin emoji_bg($x, $y) {
	background-image: url('../img/emoji/messenger/sub_#{$x}_#{$y}.png');
}

.emoji {
	position: relative;
	width: 20px;
	height: 20px;
	background-size: 100%;
	background-repeat: no-repeat;
	border: none;
	display: inline-block;
	vertical-align: text-top;
}

.emoji.emoji__md {
	width: 23px;
	height: 23px;
}

.emoji.emoji__sm {
	width: 18px;
	height: 18px;
}

.emoji.emoji__xs {
	width: 14px;
	height: 14px;
}

.emoji.emoji__angry {
	@include emoji_bg(60.784, 7.843);
}
.emoji.emoji__confused {
	@include emoji_bg(58.824, 88.235);
}
.emoji.emoji__crying,
.emoji.emoji__cry {
	@include emoji_bg(60.784, 11.765);
}
.emoji.emoji__grinning {
	@include emoji_bg(58.824, 47.059);
}
.emoji.emoji__heart_eyes,
.emoji.emoji__heart-eyes {
	@include emoji_bg(58.824, 72.549);
}
.emoji.emoji__neutral_face,
.emoji.emoji__neutral {
	@include emoji_bg(58.824, 78.431);
}
.emoji.emoji__disappointed,
.emoji.emoji__sad {
	@include emoji_bg(60.784, 3.922);
}
.emoji.emoji__sleepy {
	@include emoji_bg(60.784, 27.451);
}
.emoji.emoji__smile,
.emoji.emoji__smiling {
	@include emoji_bg(58.824, 54.902);
}
.emoji.emoji__open_mouth,
.emoji.emoji__surprised {
	@include emoji_bg(60.784, 35.294);
}
.emoji.emoji__tired_face,
.emoji.emoji__tired {
	@include emoji_bg(60.784, 29.412);
}
.emoji.emoji__stuck_out_tongue,
.emoji.emoji__tongue-out {
	@include emoji_bg(58.824, '100.000');
}
.emoji.emoji__wink {
	@include emoji_bg(58.824, 64.706);
}
.emoji.emoji__moyai {
	@include emoji_bg(58.824, 45.098);
}
.emoji.emoji__dislike,
.emoji.emoji__unlike,
.emoji.emoji__thumbsdown {
	@include emoji_bg(29.412, 5.882);
}
.emoji.emoji__like,
.emoji.emoji__thumbsup {
	@include emoji_bg(27.451, 96.078);
}
.emoji.emoji__ok_hand {
	@include emoji_bg(27.451, 84.314);
}
.emoji.emoji__octopus {
	@include emoji_bg('25.490', 5.882);
}
.emoji.emoji__pig2 {
	@include emoji_bg('25.490', '0.000');
}
.emoji.emoji__wave {
	@include emoji_bg(27.451, 72.549);
}
.emoji.emoji__mouse {
	@include emoji_bg('25.490', 45.098);
}
.emoji.emoji__mouse2 {
	@include emoji_bg(23.529, 60.784);
}
.emoji.emoji__rat {
	@include emoji_bg(23.529, 58.824);
}

.emoji.emoji__ticket {
	@include emoji_bg(17.647, 9.804);
}
.emoji.emoji__scream {
	@include emoji_bg(60.784, 41.176);
}

.emoji_picker {
	cursor: default;
	display: flex;
	flex-direction: row;
	z-index: 20;
	position: absolute;
	width: 270px;
	background: $white;
	color: $black;
	border-radius: 10px;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	right: 35px;
	bottom: 45px;
	flex-wrap: wrap;
	padding: $spacer;
	justify-content: space-between;
	align-items: center;
	opacity: 1;
}

.emoji.emoji__preview {
	width: 30px;
	height: 30px;
	margin: 10px;
}

.emoji_picker__item {
	opacity: 1;
	cursor: pointer;
	background-size: 50%;
	width: 50px;
	height: 50px;
	transition: background-color 0.2s;
	&:hover {
		background-color: $gray-300;
	}
	border-radius: 100%;
}

.emoji_picker_wrapper {
	display: inline-block;
}

.emoji_wrapper {
	display: inline-block;
	line-height: 1;
}

// this is css class for lexical editor emoji, display background image for unicdoe emoji
// emoji code source was get hin ttps://emojipedia.org
.lexical-emoji {
	color: transparent;
	background-size: 16px 16px;
	background-position: center;
	background-repeat: no-repeat;
  caret-color: $gray-900;

	&.thumbsup {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f44d.svg');
	}
	&.thumbsdown {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f44e.svg');
	}
	&.angry {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f620.svg');
	}
	&.confused {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f615.svg');
	}
	&.cry {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f622.svg');
	}
	&.grinning {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f600.svg');
	}
	&.heart_eyes {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f60d.svg');
	}
	&.neutral {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f610.svg');
	}
	&.sleepy {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62a.svg');
	}
	&.pensive {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f614.svg');
	}
	&.smile {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f604.svg');
	}
	&.tongue_out {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f61b.svg');
	}
	&.tired {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62b.svg');
	}
	&.surprise {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f62e.svg');
	}
	&.wink {
		background-image: url('https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f609.svg');
	}
}

.lexical-emoji-inner {
}
