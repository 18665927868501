.component-show-list {
	top: 32px;
	right: 0px;
	z-index: 2;
	border: 1px solid #dadce0;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	overflow: hidden;
	color: #000000;
	background: #fff;
}

.rating {
}

.convo-feedback__icon {
	margin-right: 10px;
}

.convo-feedback__icon img {
	display: block;
}

.convo-feedback__content span:first-child {
	opacity: 0.6;
}

.convo-feedback__content {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.convo-notfeedback span {
	opacity: 0.6;
	color: #000000;
	margin: 0 2px;
}

.convo_header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid $section-border-color;
	padding-left: 15px;
	padding-right: 7px;
	height: 64px;
	position: relative;
	border-bottom: 1px solid $section-border-color;
	background-color: $white;
}

.convo_header__info {
	align-self: center;
	overflow: hidden;
	// margin-bottom: 4px;
	flex: 1;
}

.convo_header__title {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
	align-items: center;
	flex-direction: row;
	margin-right: $spacer;
	line-height: 1.1;
	height: 22px;
}

.convo_header__main {
	flex-grow: 0;
	margin: 0 $spacer 0 $spacer;
	display: flex;
	align-items: center;
}

.convo_header__title_edit {
	max-width: 620px;
	//margin-bottom: 5px;
	font-weight: 500;
	font-size: 16px;
}

.assign_agent {
	width: 350px;
	position: relative;
	max-height: 600px;
	overflow: auto;
	background: white;
	display: flex;
	flex-direction: column;
}

.assign_agent__header {
	display: flex;
	border-bottom: 1px solid #eeeeee;
	justify-content: space-between;
	padding: $spacer $spacer * 2;
	font-size: $font-size-lg;

	font-weight: 500;
}

.assign_agent__member {
	display: flex;
	white-space: nowrap;
	align-items: center;
	margin-bottom: $spacer * 2;
}

.assign_agent__member_avatar {
}

.assign_agent__member_email {
	color: $gray-800;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.assign_agent__member_info {
	flex-grow: 1;
	margin-left: $spacer;
	margin-right: $spacer;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.assign_agent__member_name {
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.assign_agent__member_title {
}

.assign_agent__member_status {
	user-select: none;
	color: green;
}

.assign_agent__label {
	font-weight: 500;
	margin: $spacer * 4 0 $spacer 0;
	color: $gray-600;
}

.convo_header__members {
	cursor: pointer;
	flex-shrink: 0;
}

.convo_header__rating.convo_header__rating__disabled {
	color: $gray-300;
	cursor: default;
}

.convo_header__rating {
	cursor: pointer;
	color: $gray-600;
	white-space: nowrap;
	display: flex;
	align-items: center;

	.convo_header__rating__star {
		stroke: $gray-600;
		margin-right: $spacer * 0.5;
	}

	&:hover {
		color: $black;
		.convo_header__rating__star {
			stroke: $orange;
			fill: $yellow;
		}
	}
}

.convo_header__rating__img {
	width: 18px;
	height: 18px;
}

.convo_header__extra {
	display: flex;
	line-height: 1.1;
	padding: 0;
	// height: 22px; // keeping header static
	// border-top: 1px solid #e9ecef;
	align-items: center;
	font-size: 13px;
}

.convo_header__channel_icon {
	margin-right: $spacer;
}

.convo_header__tags {
	flex: 1;
	display: flex;
	align-items: center;
	position: relative;
}

.add_tag {
	width: 450px;
	min-height: 450;
	position: relative;
	max-height: 600px;
	overflow: auto;
	background: white;
	padding: $spacer * 2;
	display: flex;
	flex-direction: column;
}

.add_tag__header {
	display: flex;
	border-bottom: 1px solid #eeeeee;
	justify-content: space-between;
	padding: 0 0 $spacer 0;
	font-size: $font-size-lg;
	font-weight: 500;
}

.assign_agent__body {
	overflow: auto;
	padding: $spacer 2 * $spacer;
}

.add_tag__tag {
	display: inline-block;
}

.add_tag__member_status {
	user-select: none;
	color: green;
	margin-top: $spacer;
	padding: $spacer;
	display: flex;
}

.add_tag__label {
	margin: $spacer * 2 0 $spacer 0;
	color: $gray-600;
}

.add_tag__used_tags {
	min-height: 100px;
	background: #eff8ff;
	border: 2px dashed #03a9f4;
	padding: 10px;
	border-radius: 10px;
}

.add_tag__add_more_tag_btn {
	border: 1px dashed #cfcfcf !important;
	color: #cfcfcf !important;
	&:hover {
		border-color: black !important;
		color: black !important;
	}
}

.add_tag__empty_tag {
	color: #8ba2b5;
	margin: auto;
	margin-top: 26px;
	text-align: center;
}

.convo_header__top {
	display: flex;
	padding: $spacer;
	padding-bottom: 0;
	justify-content: space-between;
	align-items: center;
}

.convo_header__actions {
	display: flex;
	white-space: nowrap;
	align-items: center;
}

.convo_header__delimiter {
	height: 14px;
	border-left: 1px solid #ccc;
	margin: 0 $spacer;
}

.convo_input {
	padding: 0 $spacer;
}

.convo_input_wrapper.instagram_comment {
	width: 800px;
	max-width: 90%;
}

.convo_input_wrapper {
	width: 100%;
	max-width: 750px;
	margin: 0 auto;

	&.email {
		max-width: unset;
		padding: 0 20px;
	}
}

.convo_input.convo_input__comment {
	max-width: 500px;
	margin: 0 auto;
	padding: 0 0;
}

.convo_input > .message_editor.message_editor__plain {
	background: white;
}

.convo_header__source {
	display: flex;
	align-items: center;
	overflow: hidden;
}

.convo_header__source_channel {
	flex-shrink: 0;
	color: $secondary;
}

.convo_header__source_link {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: $gray-600;
	cursor: pointer;
	&:hover {
		color: $black;
	}
}

.convo_header__source_img {
	width: 16px;
	height: 16px;
	flex-shrink: 0;

	.emoji {
		width: 100%;
		height: 100%;
	}
}

.convo_header__dropdown_container {
	position: relative;
}

.convo_header__dropdown {
	position: absolute;
	min-width: 200px;
	z-index: 2;
	top: 100%;
	right: 0;

	margin-top: $spacer * 0.5;

	box-shadow:
		0px 1px 8px rgba(0, 0, 0, 0.06),
		0px 4px 32px rgba(0, 0, 0, 0.06),
		0px 8px 64px rgba(0, 0, 0, 0.08);

	border-radius: 8px;
}

.convo_header__dropdown_item {
	padding: $spacer * 0.5 $spacer;
	display: flex;
	align-items: center;
	cursor: pointer;
	background: $white;

	&:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding-top: 0.6 * $spacer;
	}

	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding-bottom: 0.6 * $spacer;
	}

	&:hover {
		color: white;
		background: $primary; // #E8F0FF;
		.convo_header__dropdown_icon {
			color: white;
		}
		// background: #E8F0FF;
	}
}

.convo_header__dropdown_icon {
	color: $gray-500;
	margin-right: $spacer;
}

.convo_header__done_btn {
	cursor: pointer;
	width: 20px;
	height: 20px;
	background-size: contain;
	background-image: url('../img/done_gray.svg');
	&:hover {
		background-image: url('../img/done.svg');
	}
}

.convo_header__done_btn.convo_header__done_btn__disabled {
	opacity: 0.3;
	cursor: default;
	&:hover {
		background-image: url('../img/done_gray.svg');
	}
}

.convo_header__clickable {
	stroke: $gray-600;

	cursor: pointer;
	&:hover {
		stroke: $black;
	}
}

.convos_header__check_mark {
	border: 2px solid #adb5bd;
	width: 20px;
	height: 20px;
	padding: 4px 3px 2px 3px;
	border-radius: 4px;
	margin: 8px 0;
	align-items: center;
	justify-content: center;
	background-color: white;
	color: $white;
	display: flex;
}

.convos_header__check_mark.convos_header__check_mark__unchecked {
	border: 2px solid $primary;
	background-color: $primary;
	color: $primary;
}

.convos_header__check_mark.convos_header__check_mark__checked {
	border: 2px solid $primary;
	background-color: $primary;
}

.convos_header__check_num {
	top: 3px;
	font-size: 11px;
	right: -6px;
	background: $gray-500;
	border-radius: 10px;
	position: absolute;
	font-weight: 500;
	color: $white;
	padding: 0 $spacer * 0.5;
}

.convos_header__check {
	cursor: pointer;
	position: relative;
}

.convo_header__done_icon {
	width: 16px;
	height: 16px;
	background: url(../img/done_black.svg) no-repeat center / contain;
	flex-shrink: 0;
}

.convo_header__done_icon_unread {
	width: 14px;
	height: 14px;
	background: url(../img/unverified.svg) no-repeat center / contain;
	flex-shrink: 0;
}

.convo-tags-input {
	display: flex;
	flex-wrap: wrap;
	border: 1px solid $input-border-color;
	padding-left: 5px;
	min-height: 32px;

	&.is-focused {
		border-color: $input-focus-color;
	}
}

.convo-tags-input__input-wrapper {
	margin: 5px 5px 5px 0;
}

.convo-tags-input__input {
	height: 100%;
	border: none;
	outline: none;
}

.convo_header__extra2 {
	display: flex;
	left: 0;
	right: 10px;
	line-height: 1.1;
	padding: 0;
	padding-left: 10px;

	// height: 22px; // keeping header static
	border-bottom: 1px solid #ececec;
	align-items: center;
	background: #ffffff;
	z-index: 2;
}

.internal_convo {
	z-index: $zindex-dropdown;
	width: 350px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 450px;
	position: absolute;
	top: 55px;
	right: -1px;

	border: 1px solid #d5d5d5;
	background: $white;
	// box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	box-shadow: 0 5px 10px 0 #0000002e;
	border-radius: 3px;
	transition:
		opacity 250ms ease,
		visibility 300ms ease;
	visibility: visible;
	opacity: 1;
}

.internal_convo.internal_convo__hide {
	visibility: hidden;
	opacity: 0;
}

.internal_convo_header {
	background: whitesmoke;
	display: flex;
	justify-content: space-between;
	flex-shink: 0;
	padding: 10px 10px;
	font-size: 16px;
	font-weight: 500;
	border-bottom: 1px solid #eee;
}

.mention_mark {
	color: $primary;
	border-radius: 50%;
	font-size: 15px;
	font-weight: 600;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	text-align: center;
}

.user_activities {
	position: absolute;
	top: 0;
	left: 1500px;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: $white;
	transition: 0.3s;
	padding: 20px;
	overflow: auto;
	//display: none;

	&.active {
		left: 0;
		//display: block;
	}
}

.user_activities_day {
	margin-bottom: $spacer * 2.5;

	&:last-child {
		margin-bottom: 0;
	}
}

.user_activities_day_header {
	display: flex;
	align-items: center;
}

.user_activities_day_seperator {
	padding: 0 90px;
	margin-bottom: 25px;
	padding-top: 0;
	color: $gray-600;
	position: relative;
	display: inline-block;
	font-size: 13px;

	&:before {
		content: '';
		width: 70px;
		height: 1px;
		left: 0;
		top: 50%;
		position: absolute;
		background-color: $border-color;
	}

	&:after {
		content: '';
		width: 70px;
		height: 1px;
		right: 0;
		top: 50%;
		position: absolute;
		background-color: $border-color;
	}
}

.user_activities_event_img {
	width: 20px;
	height: 20px;
	object-fit: cover;
}

.user_activities_event_icon_wrapper {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	&.note {
		background-color: #ffe09e;
		color: #ffbb28;

		svg path:nth-child(3) {
			fill: $white;
		}
	}

	&.user_info_updated {
		background-color: #ff4a75;
		color: $white;
	}

	&.content_viewed {
		background-color: $gray-500;
		color: $white;
	}

	&.mail {
		background-color: #e74f41;
		color: $white;
	}
}

.user_activities_day_content {
	padding-top: $spacer;
}

.user_activities_event {
	display: flex;
	margin-bottom: $spacer;

	&:last-child {
		margin-bottom: 0;
	}
}

.convo_header__tags .convo_tag.convo_tag__add_btn {
	border-color: transparent;
	color: $gray-500;

	&:hover {
		border: 1px solid #dfe1e4;
		background-color: #fbfbfc;
		color: $gray-700;
	}
}

.message_call_wrapper {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-top: 3px;

	&.message_call_wrapper__user {
		margin-left: 50px;
	}

	&.message_call_wrapper__agent {
		margin-right: 50px;
		flex-direction: row-reverse;
	}
}

.message_call {
	display: flex;
	padding: 4px 12px;
	background-color: $gray-300;
	border-radius: 9999px;
	cursor: pointer;
	align-items: center;
}

.message_content_viewed_link {
	display: inline-flex;
	align-items: center;
	opacity: 0.7;
	font-weight: 500;
	text-decoration: underline;
	overflow: hidden;
	color: $gray-700;

	&:hover {
		color: $gray-700;
		opacity: 1;
		text-decoration: underline;
	}
}

.message_email_ev_box {
	&.message_email_ev_box__user {
		margin-left: 50px;
	}
	&.message_email_ev_box__agent {
		margin-right: 50px;
	}
	margin-top: 5px;
	background-color: $white;
	border: 1px solid $border-color;
	border-radius: 8px;
	cursor: pointer;
	font-size: 14px;
	padding: 5px 12px;
	max-width: 300px;
}

.message_payment_box {
	&.message_payment_box__user {
		margin-left: 50px;
	}
	&.message_payment_box__agent {
		margin-right: 50px;
	}
	margin-top: 5px;
	background-color: $white;
	border: 1px solid $border-color;
	border-radius: 8px;
	padding: 10px 12px;
	width: 300px;
}

.message_payment_box_icon_wrapper {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	background-color: $success;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.message_cross_channel_box {
	&.message_cross_channel_box__user {
		margin-left: 50px;
	}
	&.message_cross_channel_box__agent {
		margin-right: 50px;
	}
	margin-top: 5px;
	background-color: $white;
	border: 1px solid $border-color;
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;
	padding: 10px;
	width: 200px;
}

.message_event_help_text {
	margin-top: 3px;
	font-size: 10px;
	@extend .text__muted;
  color: $gray-700;

	&.agent {
		margin-right: 50px;
	}
	&.user {
		margin-left: 50px;
	}
}
